<template>
    <div class="bigBox">
        <div class="solution-top">
            <div class="solution-top-box">
                <div class="solution-top-text"> Products and Solutions</div>
                <div class="solution-top-text1">解决方案与产品</div>
            </div>
            <div class="solution-top-box1">
                <div class="solution-top-box1-underline"></div>
                <div class="solution-top-box1-text">让我们解决您的问题</div>
                <div class="solution-top-box1-underline"></div>
            </div>
        </div>
        <div class="nodeFull solution-bottom-container">
            <div class="solution-bottom">
                <div class="solution-bottom-box" @click="$router.push('/solution/1')">
                    <div class="solution-bottom-box-Top">
                        <div class="box-Top-icon">
                        </div>
                        <div class="box-Top-number">01</div>
                    </div>
                    <div class="solution-bottom-box-bot">
                        <div class="title"> 互联网+医疗</div>
                        <div class="content" title="以优化区域医疗资源配置，提升基层医疗服务的可及性，提高基层医疗卫生机构的诊疗能力为目标，构建涵盖网络诊断、网络会诊、网络教育、网络医疗运营的全面解决方案。">
                            以优化区域医疗资源配置，提升基层医疗服务的可及性，提高基层医疗卫生机构的诊疗能力为目标，构建涵盖网络诊断、网络会诊、网络教育、网络医疗运营的全面解决方案。
                        </div>
                        <div class="more">
                            <span>Read More</span><span class="iconfont icon-tuxing"></span>
                        </div>
                    </div>

                </div >
                <div class="solution-bottom-box" @click="$router.push('/solution/2')">
                    <div class="solution-bottom-box-Top">
                        <div class="box-Top-icon">
                        </div>
                        <div class="box-Top-number">02</div>
                    </div>
                    <div class="solution-bottom-box-bot">
                        <div class="title"> 区域医疗与数据共享</div>
                        <div class="content" title="基层公卫与信息化的整体解决方案，意义在于将基层的信息化系统和公共卫生系统深度结合使用。">
                            基层公卫与信息化的整体解决方案，意义在于将基层的信息化系统和公共卫生系统深度结合使用。
                        </div>
                        <div class="more">
                            <span>Read More</span><span class="iconfont icon-tuxing"></span>
                        </div>
                    </div>
                </div>
                <div class="solution-bottom-box" @click="$router.push('/solution/3')">
                    <div class="solution-bottom-box-Top">
                        <div class="box-Top-icon"> </div>
                        <div class="box-Top-number">03</div>
                    </div>
                    <div class="solution-bottom-box-bot">
                        <div class="title"> 院内信息化解决方案</div>
                        <div class="content" title="使医院信息系统平台形成一个标准化、集成化、智能化的信息平台，并建立对外连接医保、公共卫生、区域健康、社区医疗等多个信息系统，实现医院信息的规范化和一体化管理。">
                            使医院信息系统平台形成一个标准化、集成化、智能化的信息平台，并建立对外连接医保、公共卫生、区域健康、社区医疗等多个信息系统，实现医院信息的规范化和一体化管理。
                        </div>
                        <div class="more">
                            <span>Read More</span><span class="iconfont icon-tuxing"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Vue, Component} from "vue-property-decorator";

    @Component
    export default class SolutionInfoView extends Vue {

    }
</script>

<style lang="scss" scoped>
.bigBox{
    background:#fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    .solution-top {
        // width: 19.20rem;
        height: 5.23rem;
        background: rgba(255, 255, 255, 1);
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:12.66rem;

        .solution-top-box {
            position: relative;

            .solution-top-text {
                height: 1.71rem;
                font-size: 1.20rem;
                font-family: DIN-BlackItalic;
                font-weight: 400;
                line-height: 0.72rem;
                color: rgba(239, 239, 239, 1);
                opacity: 1;
            }

            .solution-top-text1 {
                width:100%;
                height: 100%;
                position: absolute;
                top: -0.5rem;
                left: 0rem;
                height: 1.12rem;
                font-size: 0.80rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: rgba(43, 43, 43, 1);
                opacity: 1;
                text-align: center;
            }
        }

        .solution-top-box1 {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            .solution-top-box1-underline {
                width: 4.89rem;
                height: 0.01rem;
                background: rgba(221, 221, 221, 1);
                opacity: 1;
                margin-top: 0.4rem;
            }

            .solution-top-box1-text {
                height: 0.33rem;
                font-size: 0.24rem;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 0.72rem;
                color: rgba(43, 43, 43, 1);
                padding-left: 0.2rem;
                padding-right: 0.2rem;
                opacity: 1;
            }
        }
    }

    .solution-bottom-container {
        justify-content: center;
        align-items: center;
        background: rgba(248, 253, 251, 1);
        width:100%;

        .solution-bottom {
            width: 12.66rem;
            height: 5.23rem;
            opacity: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .solution-bottom-box {
                position: relative;
                width: 3.76rem;
                height: 2.4021rem;
                background-color: #FFF;
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
                opacity: 1;
                border-radius: 0.50rem 0.04rem 0.04rem 0.04rem;
                cursor: pointer;
                padding: 0rem 0.2rem;
                box-sizing: border-box;

                .solution-bottom-box-Top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .box-Top-icon {
                        width: 1.32rem;
                        height: 1.14rem;
                        background:  url('~@/assets/images/solution/meal.png') no-repeat center center;
                    }

                    .box-Top-number {
                        font-size: 0.8rem;
                        font-family: Poppins-Medium;
                        color: #f3f3f3;
                    }
                }

                .solution-bottom-box-bot {
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-family: Microsoft YaHei;
                        font-size: 0.15rem;
                        font-weight: bold;
                    }

                    .content {
                        padding-top: 0.1rem;
                        font-family: Raleway-Medium;
                        // color: #8CD9B8;
                        font-size: 0.15rem;
                        line-height: 0.24rem;
                        height:0.48rem;
                        overflow: hidden;
                    }

                    .more {
                        padding-top: 0.1rem;
                        color: #333;
                        display: flex;
                        align-content: center;
                        font-size: 0.12rem;
                        .iconfont{
                            margin-left: 0.1rem;
                        }
                    }
                }
            }

            .solution-bottom-box:hover {
                background: rgba(41, 180, 115, 1);

                .box-Top-icon{
                    width: 1.32rem;
                    height: 1.14rem;
                    background:  url('~@/assets/images/solution/meal2.png') no-repeat center center;
                }
                .title {
                    color: #FFF;
                }

                .box-Top-number {
                    color: #2EBD86;
                }
                .more{
                    color:#FFF;
                }
                .content{
                    color: #8CD9B8;
                }
            }
        }

    }
</style>
