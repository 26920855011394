<template>
    <div>
        <div class="solution-top">
            <div class="solution-top-box">
                <div class="solution-top-text"> Business Cooperation</div>
                <div class="solution-top-text1">商务合作</div>
            </div>
            <div class="solution-top-box1">
                <div class="solution-top-box1-underline"></div>
                <div class="solution-top-box1-text">洽谈事宜，请发送邮件至官方邮箱，也可以拨打企业电话，期待与您的合作</div>
                <div class="solution-top-box1-underline"></div>
            </div>
        </div>
        <div class="Fourth-box-3">
            <div class="Fourth-box-3-left">
                <div class="Fourth-box-3-left-text">联系我们</div>
                <div class="Fourth-box-3-left-text1">
                    <p class="Fourth-box-3-left-text1-underline"></p>
                    <p style="padding-left: 0.2rem;padding-right: 0.2rem"> CONTACT US</p>
                    <p class="Fourth-box-3-left-text1-underline"></p>
                </div>
                <div class="Fourth-box-3-leftbox">
                    <div class="leftbox-user">
                        <span style="color:rgba(158,156,154,1);">姓名</span>
                    </div>
                    <div class="leftbox-call">
                        <span style="color:rgba(158,156,154,1);">电话</span>
                    </div>
                </div>
                <div class="Fourth-box-3-des">
                    <span style="color:rgba(158,156,154,1);">说些什么吧</span>
                </div>
                <div class="Fourth-box-3-btn">
                    <span>提交</span>
                </div>
            </div>
            <div class="Fourth-box-3-right">
                <div style="margin-top:0.8rem"></div>
                <div class="Fourth-box-3-right-box">
                    <div class="Fourth-box-3-right-box1">
                        <div class="right-box1-icon">
                            <img width="121" height="121" src="~@/assets/images/HomeImage/phone.png" alt="">
                        </div>
                        <div class="right-box1-text">
                            <p class="text-line1">联系电话 (9:00am - 18:00pm)</p>
                            <p class="text-line2">+028-61674043</p>
                        </div>
                    </div>
                    <div class="Fourth-box-3-right-box1">
                        <div  class="right-box1-icon">
                            <img width="121" height="121" src="~@/assets/images/HomeImage/youjian.png" alt="">
                        </div>
                        <div class="right-box1-text">
                            <p class="text-line1">邮箱</p>
                            <p class="text-line2">admin@bq-health.com</p>
                        </div>
                    </div>
                    <div class="Fourth-box-3-right-box1">
                        <div  class="right-box1-icon">
                            <img width="121" height="121" src="~@/assets/images/HomeImage/ditu.png" alt="">
                        </div>
                        <div class="right-box1-text">
                            <p class="text-line1">地址</p>
                            <p class="text-line2">成都市高新区天华一路99号天府软件园B区3座407 </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import {Vue, Component} from "vue-property-decorator";

	@Component
	export default class BusinessView extends Vue {

	}
</script>

<style lang="scss" scoped>
    .solution-top {
        // width: 19.20rem;
        height: 5.23rem;
        background: rgba(255, 255, 255, 1);
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .solution-top-box {
            position: relative;

            .solution-top-text {
                height: 1.71rem;
                font-size: 1.40rem;
                font-family: DIN-BlackItalic;
                font-weight: 400;
                line-height: 0.72rem;
                color: rgba(239, 239, 239, 1);
                opacity: 1;
            }

            .solution-top-text1 {
                position: absolute;
                top: -0.5rem;
                left: 5rem;
                height: 1.12rem;
                font-size: 0.80rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: rgba(43, 43, 43, 1);
                opacity: 1;
            }
        }

        .solution-top-box1 {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            .solution-top-box1-underline {
                width: 2.20rem;
                height: 0.01rem;
                background: rgba(221, 221, 221, 1);
                opacity: 1;
                margin-top: 0.4rem;
            }

            .solution-top-box1-text {
                height: 0.33rem;
                font-size: 0.24rem;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 0.72rem;
                color: rgba(43, 43, 43, 1);
                padding-left: 0.2rem;
                padding-right: 0.2rem;
                opacity: 1;
            }
        }
    }

    .Fourth-box-3 {
        height:9.64rem;
        // width:19.20rem;
        background:rgba(248,253,251,1);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .Fourth-box-3-left {
            width:6.35rem;
            height:6.77rem;
            background:rgba(2,1,0,1);
            opacity:1;
            border-radius:1rem 0.04rem 0.04rem 0.04rem;
            margin-right: 0.6rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .Fourth-box-3-left-text {
                font-size:0.4rem;
                font-family:FZShangKuS-R-GB;
                font-weight:400;
                line-height:0.58rem;
                color:rgba(255,255,255,1);
                margin-top: 0.68rem;
                opacity:1;
            }
            .Fourth-box-3-left-text1 {
                font-size:0.6rem;
                font-family:OleoScriptSwashCaps-Regular;
                line-height:0.58rem;
                color:rgba(255,255,255,1);
                opacity:0.2;
                margin-top: 0.1rem;
                display: flex;
                .Fourth-box-3-left-text1-underline {
                    width:0.69rem;
                    height:0.01rem;
                    background:rgba(241,96,43,1);
                    opacity:1;
                    margin-top: 0.3rem;

                }
            }
            .Fourth-box-3-leftbox {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                margin-top:0.43rem;
                .leftbox-user {
                    width:2.20rem;
                    height:0.5rem;
                    background:rgba(14,14,14,1);
                    opacity:1;
                }
                .leftbox-call {
                    width:2.20rem;
                    height:0.5rem;
                    background:rgba(14,14,14,1);
                    opacity:1;
                    margin-left: 0.2rem;
                }

            }
            .Fourth-box-3-des {
                width:5.05rem;
                height:2.23rem;
                background:rgba(14,14,14,1);
                opacity:1;
                margin-top: 0.2rem;
            }
            .Fourth-box-3-btn {
                width:2rem;
                height:0.55rem;
                background:rgba(41,180,115,1);
                box-shadow:0px 0.10rem 0.27rem rgba(9,248,133,0.19);
                opacity:1;
                border-radius:0.28rem;
                margin-top: 0.46rem;
                text-align: center;
                line-height:0.55rem;
                span {
                    font-size:0.18rem;
                    font-family:Roboto-Regular;
                    color:rgba(255,255,255,1);
                    opacity:1;
                }
            }
        }
        .Fourth-box-3-right {
            width:4.60rem;
            height:6.77rem;
            background:rgba(41,180,115,1);
            opacity:1;
            border-radius:0.04rem 0.04rem 1.01rem 0.04rem;
            .Fourth-box-3-right-box {
                display: flex;
                flex-direction: column;
                .Fourth-box-3-right-box1 {
                    display: flex;
                    flex-direction: row;
                    line-height: 0.4rem;
                    margin-top: 0.3rem;
                    /*margin-left: 0.2rem;*/
                    .right-box1-icon {
                        /*width:1.21rem;*/
                        /*height:1.21rem;*/
                    }
                    .right-box1-text {
                        padding-top: 0.2rem;
                        .text-line1 {
                            font-size:0.22rem;
                            font-family:Poppins-Medium;
                            color:rgba(248,253,251,1);
                            opacity:0.7;
                        }
                        .text-line2 {
                            font-size:0.18rem;
                            font-family:OpenSans-Regular;
                            color:rgba(248,253,251,1);
                            opacity:1;
                            width: 2.17rem;
                        }
                    }
                }
            }
        }
    }
</style>
