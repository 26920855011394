var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bigBox"},[_vm._m(0),_c('div',{staticClass:"nodeFull solution-bottom-container"},[_c('div',{staticClass:"solution-bottom"},[_c('div',{staticClass:"solution-bottom-box",on:{"click":function($event){return _vm.$router.push('/solution/1')}}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"solution-bottom-box",on:{"click":function($event){return _vm.$router.push('/solution/2')}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"solution-bottom-box",on:{"click":function($event){return _vm.$router.push('/solution/3')}}},[_vm._m(5),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-top"},[_c('div',{staticClass:"solution-top-box"},[_c('div',{staticClass:"solution-top-text"},[_vm._v(" Products and Solutions")]),_c('div',{staticClass:"solution-top-text1"},[_vm._v("解决方案与产品")])]),_c('div',{staticClass:"solution-top-box1"},[_c('div',{staticClass:"solution-top-box1-underline"}),_c('div',{staticClass:"solution-top-box1-text"},[_vm._v("让我们解决您的问题")]),_c('div',{staticClass:"solution-top-box1-underline"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-bottom-box-Top"},[_c('div',{staticClass:"box-Top-icon"}),_c('div',{staticClass:"box-Top-number"},[_vm._v("01")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-bottom-box-bot"},[_c('div',{staticClass:"title"},[_vm._v(" 互联网+医疗")]),_c('div',{staticClass:"content",attrs:{"title":"以优化区域医疗资源配置，提升基层医疗服务的可及性，提高基层医疗卫生机构的诊疗能力为目标，构建涵盖网络诊断、网络会诊、网络教育、网络医疗运营的全面解决方案。"}},[_vm._v(" 以优化区域医疗资源配置，提升基层医疗服务的可及性，提高基层医疗卫生机构的诊疗能力为目标，构建涵盖网络诊断、网络会诊、网络教育、网络医疗运营的全面解决方案。 ")]),_c('div',{staticClass:"more"},[_c('span',[_vm._v("Read More")]),_c('span',{staticClass:"iconfont icon-tuxing"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-bottom-box-Top"},[_c('div',{staticClass:"box-Top-icon"}),_c('div',{staticClass:"box-Top-number"},[_vm._v("02")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-bottom-box-bot"},[_c('div',{staticClass:"title"},[_vm._v(" 区域医疗与数据共享")]),_c('div',{staticClass:"content",attrs:{"title":"基层公卫与信息化的整体解决方案，意义在于将基层的信息化系统和公共卫生系统深度结合使用。"}},[_vm._v(" 基层公卫与信息化的整体解决方案，意义在于将基层的信息化系统和公共卫生系统深度结合使用。 ")]),_c('div',{staticClass:"more"},[_c('span',[_vm._v("Read More")]),_c('span',{staticClass:"iconfont icon-tuxing"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-bottom-box-Top"},[_c('div',{staticClass:"box-Top-icon"}),_c('div',{staticClass:"box-Top-number"},[_vm._v("03")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-bottom-box-bot"},[_c('div',{staticClass:"title"},[_vm._v(" 院内信息化解决方案")]),_c('div',{staticClass:"content",attrs:{"title":"使医院信息系统平台形成一个标准化、集成化、智能化的信息平台，并建立对外连接医保、公共卫生、区域健康、社区医疗等多个信息系统，实现医院信息的规范化和一体化管理。"}},[_vm._v(" 使医院信息系统平台形成一个标准化、集成化、智能化的信息平台，并建立对外连接医保、公共卫生、区域健康、社区医疗等多个信息系统，实现医院信息的规范化和一体化管理。 ")]),_c('div',{staticClass:"more"},[_c('span',[_vm._v("Read More")]),_c('span',{staticClass:"iconfont icon-tuxing"})])])
}]

export { render, staticRenderFns }