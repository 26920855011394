
	import {Vue, Component} from "vue-property-decorator";
	import ContactUs from "components/contactUs/index.vue";

	@Component({
        components:{
            ContactUs,
        }
    })
	export default class RecruitView extends Vue {
		imageOne = require("assets/images/joinUs/Rectangle.png");
	}
