<template>
  <div>
    <div class="Internet-top">
      <div class="Internet-top-innerBox">
        <div class="top-innerBox-number">
          <div style="position:relative;opacity: 0.45;">01</div>
          <div class="top-innerBox-text">互联网+医疗解决方案</div>
        </div>

        <div class="top-innerBox-text1">
          <p>以分级诊疗为核心，以实体医院为主体，</p>
          <p>打通服务基层患者、医生的最后壁垒，实现以患者为核心的诊疗理念，</p>
          <p>以服务为己任的诊疗态度，为患者身体健康保驾护航。</p>
        </div>
      </div>
    </div>
    <div class="Internet-top1">
      <div class="Internet-top-text">
        <div style="display: flex;flex-direction: column;align-items: center">
          <p class="Internet-top-text1">TELE-CONSULTATION AND</p>
          <p
            class="Internet-top-text1"
            style="font-family:DIN;"
          >
            REMOTE DIAGNOSIS
          </p>
          <p style="font-weight: bold;font-size: 0.4rem;margin-top: 0.1rem">
            网络协诊及网络会诊解决方案
          </p>
          <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.7rem">
            网络协诊及网络会诊解决方案以优化区域医疗资源配置，提升基层医疗服务的可及性，提高基层医疗卫生机构的诊疗能力为目标，构建涵盖网络诊断、网络会诊、网络教育、网络医疗运营的全面解决方案。解决方案建设旨在实现基于医疗联合体信息平台的网络诊疗应用，借助优质医疗资源提升基层医疗卫生机构的医疗水平，丰富信息平台的医疗协同应用。方案可以实现将不同地点的医疗专家集中起来，通过网络对患者进行远程的诊疗和会诊。患者所在医疗机构医生，通过系统将患者病历、基本医疗情况及相关的检验结果、X光片、CT片、心电图、病理切片等诊断结果传输到专家的电脑。各专家基于系统接收到的资料，展开诊断，最终得到相应的处置和治疗。
          </p>
          <div style="margin-top: 0.4rem">
            <img
              width="973.61"
              height="855.11"
              src="~@/assets/images/solution/solution1.png"
              alt=""
            />
          </div>
        </div>
        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">网络协诊系统</p>
          <p class="inner-two-text2">Tele-Consultation for Paitent</p>
        </div>
        <p style="margin-top: 0.35rem">
          基于互联网的网络协诊服务系统集预约挂号、医师诊疗、检验结果录入传输、健康档案管理、药品发放为一体。施行网上远程视频诊疗服务工作，通过视频连线可以及时进行疾病防治咨询、诊断和治疗等服务。患者可以像在实地一样通过挂号或者预约某位专家，填写或扫描检验检查结果并上传，通过语音视频系统和协同面板系统和专家面对面交流。患者只需以在线问答的形式发出疑问，便能网上医生互动、交流，得到针对性的解答，听取指导性解决方案。通过在线问诊、视频对话等方式，由专家即时诊断和给出治疗方案，为初诊咨询、复诊开药以及无法来院的患者提供更加便捷的服务。网络视频诊疗服务的方式突破了时空界限，使患者在当地就能享受到专家级的服务。
        </p>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="973.61"
            height="855.11"
            src="~@/assets/images/solution/solution2.png"
            alt=""
          />
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">网络会诊系统</p>
          <p class="inner-two-text2">Remote Diagnosis</p>
        </div>
        <p style="margin-top: 0.35rem">
          网络会诊系统建立目的为解决区域内医疗资源的共享，当区域内各级基层医疗机构碰到疑难病例时，通过网络会诊系统调动上级医院的优质资源，提高疑难病例的诊断质量，这种服务模式不受地点的限制，可聘请外地资深专家参与会诊指导，提高区域内医疗服务能力，避免病人流失。同时，会诊过程可录像后成为宝贵的教学资源，可加强医生之间的交流和相互学习，不断提高业务能力。
        </p>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="973.61"
            height="855.11"
            src="~@/assets/images/solution/solution3.png"
            alt=""
          />
        </div>

        <div style="margin-top: 0.6rem">
          <img
            width="357"
            height="45"
            src="~@/assets/images/solution/group/group2.png"
            alt=""
          />
        </div>
        <div class="solution-decribe">
          <div class="solution-decribe-box">
            <div class="solution-decribe-box-cion">1</div>
            <div class="solution-decribe-box-text">
              <p style="color:#29B473;font-weight: 600">
                突破空间限制，实现医疗资源共享
              </p>
              <p>
                网络协诊和会诊系统使远在千里之外的患者能够接受到上级医疗机构专家的诊疗；也可以将远在千里之外甚至是国外的医疗专家请进“家门”，解决医学难题，为患者解除病患。
              </p>
            </div>
          </div>
          <div class="solution-decribe-box">
            <div class="solution-decribe-box-cion">2</div>
            <div class="solution-decribe-box-text">
              <p style="color:#29B473;font-weight: 600">
                节约时间，降低病人病情恶化的风险
              </p>
              <p>
                现实在组织会诊时，需提前向各位专家预约时间，确定会诊时间，安排好出差行程，耗费大量的时间和精力，而对于病情严重的病人来说，时间就是生命，病人随着时间的流逝随时存在病情恶化的可能，网络会诊系统降低了这种可能性，为病人的病情稳定争取了时间。
              </p>
            </div>
          </div>
          <div class="solution-decribe-box">
            <div class="solution-decribe-box-cion">3</div>
            <div class="solution-decribe-box-text">
              <p style="color:#29B473;font-weight: 600">
                免除疑难杂症病人的奔波之苦
              </p>
              <p>
                对于条件有限的医疗机构，如不能及时诊断病情，就需要对病人进行转院处理，而很多情况下，病人的病
                情已经不起转院的奔波劳累。网络协诊和会诊系统可以缓解病人转院的迫切性，通过网络协诊和网络会诊，
              </p>
            </div>
          </div>
          <div class="solution-decribe-box">
            <div class="solution-decribe-box-cion">4</div>
            <div class="solution-decribe-box-text">
              <p style="color:#29B473;font-weight: 600">
                提高医疗机构的医疗诊断水平。
              </p>
              <p>
                通过专家会诊过程，医生可以学习专家的诊断经验，同时向专家提出各种疑难解答，而专家在会诊过程中的思路、
                言行都将给基层医疗机构医师拓宽诊疗思路、规范诊疗程序，获取最新医疗信息和诊疗技巧带来莫大的帮助。
                另外系统良好的视频效果，还可以直接让专家为患者“望闻问切”做出诊断治疗。
              </p>
            </div>
          </div>
          <div class="solution-decribe-box">
            <div class="solution-decribe-box-cion">5</div>
            <div class="solution-decribe-box-text">
              <p style="color:#29B473;font-weight: 600">
                经济效益
              </p>
              <p>
                相比传统的医疗诊疗，网络协诊和网络会诊在一定程度上，节省了医疗机构与病人在时间和金钱上的大量开销。
              </p>
            </div>
          </div>
        </div>
        <!--第三部分-->
        <div
          style="display: flex;flex-direction: column;align-items: center;margin-top: 1rem"
        >
          <p class="Internet-top-text1">DINCond-BlackAlternate</p>
          <p
            class="Internet-top-text1"
            style="font-family:DIN;"
          >
            SERVICE SOLUTION
          </p>
          <p style="font-weight: bold;font-size: 0.4rem;margin-top: 0.1rem">
            区域诊疗服务解决方案
          </p>
          <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.7rem">
            区域诊疗服务解决方案是基于健康档案的服务平台，集成了区域检验中心、区域影像中心、区域心电中心，以服务医改为设计理念，采用标准规范化设计，
            应用云计算、大数据、物联网等前沿IT技术，纵向构建省、市、县三级卫生信息云服务网络，横向实现居民健康档案、公共卫生、医疗服务、检验中心、心电中心、影像中心的信息共享和业务协同，构建区域人口健康信息大数据，为辖区社区公共提供分级的、连续的、安全的、便捷的医疗卫生健康信息平台，提升区域内基本医疗服务的医疗水平和诊断水平，促进形成有效的分级诊疗服务体系，
            优化区域内医疗资源的配置，提高医疗服务水平和提高医疗质量，降低医疗费用，提升基层卫生管理水平和区域综合决策能力。
          </p>
          <div style="margin-top: 0.4rem">
            <img
              src="~@/assets/images/solution/solution3/solution31.png"
              alt=""
            />
          </div>
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group3.png"
            alt=""
          />
        </div>
        <div class="solution-decribe-fan">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>支持云架构部署，支持大数据存储和分析技术；</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>提供web、微信、App、终端自助服务等信息服务；</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >业界领先的数据标准管理系统，是区域医疗卫生信息共享的基础和前提；</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>多维度数据质量管理引擎；</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >支持下一代前置机技术：数据采集一体机，集成和运维更容易，信息更安全。</span
            >
          </p>
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">区域检验中心</p>
          <p class="inner-two-text2">Regional LIS Center</p>
        </div>
        <p style="margin-top: 0.35rem">
          区域检验中心是一个集数据集成、双向转诊和区域样本送检的“综合数据中心”，
          其中最主要的对区域样本送检的支持。区域样本送检是指在不同的医疗机构间进行样本送检处理，通过区域检验信息中心实现外送样本的状态全流程跟踪和检验全流程的业务支持。辅助地方临床检验中心制定、发布和管理区域临床检验质控管理规范，通过采集各级医疗卫生机构的临床检验质控数据
          ，监督各级医疗卫生机构检验质控执行情况，加速提高区域内各级医疗卫生机构的临床检验质控水平，促进区域检验结果互认。
        </p>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
              src="~@/assets/images/solution/solution3/solution32.png"
              alt=""
            />
        </div>

        <div style="margin-top: 0.6rem">
          <img
            width="127"
            height="45"
            src="~@/assets/images/solution/group/group4.png"
            alt=""
          />
        </div>
        <div class="solution-decribe-fan">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >标准的、开放的异构系统集成技术、无缝整合各级医疗卫生机构的实验室信息系统LIS；</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >即时采集、即时评价、即时反馈，实时的、动态的质控信息，提供质控信息预警功能；</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >汇总区域内医疗机构检验数据，产生区域内数据对比的报表生成。</span
            >
          </p>
        </div>

        <div style="margin-top: 0.6rem">
          <img
            width="127"
            height="45"
            src="~@/assets/images/solution/group/group6.png"
            alt=""
          />
        </div>
        <p style="margin-top: 0.36rem">
          区域检验中心将服务于区域内所有医疗卫生机构，将承担区域的大部分检验工作，以规范化、
          专业化的独立方式运作，不但能提高临床检验水平，方便病人，也便于主管部门对临床医学实验室的统一质量控制和管理。
          通过构建各县级医院和各基层医疗机构的医学检验中心系统，实现区域内信息共享。同时通过进一步的系统扩展，实现县级检验数据向上信息互联，
          构建省级区域检验中心协同系统，为医疗大数据构建、互联网构架下的分级诊疗奠定基础。
        </p>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img style="width:8.01rem;height:4.4588rem"
            src="~@/assets/images/solution/solution3/solution33.png"
            alt=""
          />
        </div>

        <div style="margin-top: 0.6rem">
          <img
            width="127"
            height="45"
            src="~@/assets/images/solution/group/group5.png"
            alt=""
          />
        </div>
        <div class="solution-decribe-fan">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>整合区域内医疗资源，避免重复投资及资源浪费； </span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>区域内统一监管标本流转，提高检验质量；</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>区域内检验结果共享，减少重复检验，提高利用率；</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>区域内检验数据统一管理，为科研、决策提供支持依据；</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>缩短患者就诊时间、减少患者就诊费用，实现新医改的目标。</span>
          </p>
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">区域影像中心</p>
          <p class="inner-two-text2">Regional PACS/RIS Center</p>
        </div>
        <p style="margin-top: 0.35rem">
          区域影像中心包括区域影像共享系统、区域影像报告查询系统、基层医疗卫生机构影像处理系统，实现跨区域跨机构的系统间影像及其他相关数据的传递和交互，通过患者主索引关联实现电子病历、医院管理、影像中心三大数据的有效融合，并发连接和接收来自区域内各级医疗机构的医学信息系统上传患者影像检查结果记录（包括诊断报告和关键影像），实现区域化集中统一归档管理。
        </p>
        <p style="margin-top: 0.2rem">
          整合区域内各级医疗卫生机构的影像检查业务，构建区域影像协作业务，在区域内提供影像集中阅片、影像会诊等医疗服务，优化区域内影像医疗资源配置，提升中小型医疗卫生机构的服务能力和医疗质量，提高就医的便捷性。
        </p>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img style="width:8.0076rem;height:6.2788rem;"
            src="~@/assets/images/solution/solution3/solution34.png"
            alt=""
          />
        </div>

        <div style="margin-top: 0.6rem">
          <img
            width="127"
            height="45"
            src="~@/assets/images/solution/group/group4.png"
            alt=""
          />
        </div>
        <div class="solution-decribe-fan">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>支持以县级以上医院带动乡镇卫生院的区域内影像业务协作;</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>基于IHE的区域影像业务集成，无缝整合异构的PACS和RIS.</span>
          </p>
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">区域心电中心</p>
          <p class="inner-two-text2">Regional ECG Center</p>
        </div>
        <p style="margin-top: 0.35rem">
          区域心电中心是指区域内多家医疗机构联网组成的心电信息系统，
          实现心电检查数据的集中诊断和统一质控，可以方便的解决基层医疗机构专业人才缺少、诊断水平薄弱、治疗不及时等问题。
          同时，对中心等大型医院内部包括门诊、住院所有心电数据的采集、记录、传输、存储、报告到全院临床发布整个过程的记录和跟踪等功能。
          还可以对院前120急救心电图检查的采集、记录实时传输到医院，使得医院及早做好心脏病人抢救准备，从而对院前急救心电检查过程的数据化管理
          。同时建立区域医疗心电诊断中心平台，利用院内心电诊断网络，将分布在区域内基层医院，社区卫生服务机构采集的心电检查数据，经过标准化和数字化，发送到区域心电网络服务器中，
          实现分散数据采集，诊断专家资源共享，创新发展区域心电检查监督和远程诊断医疗服务模式，有效提高区域内心电服务资源的利用水平和医疗服务质量。
        </p>
        <div style="margin-top: 0.2rem">
          <img
            width="973.61"
            src="~@/assets/images/solution/solution3/solution30.png"
            alt=""
          />
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="127"
            height="45"
            src="~@/assets/images/solution/group/group4.png"
            alt=""
          />
        </div>
        <div class="solution-decribe-fan">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >通过数据集中管理，实现区域数据共享和远程报告。突破空间、时间的限制，将优质的上级医院心电资源向基层社区延伸；
            </span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >通过诊断组和专家诊断平台，实现区域内医疗心电资源的重组和共享;</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >通过对检查、诊断数据的分析，实现对区域心电检查的质量监控、预警等管理功能;</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>支持智能终端，专家可移动诊断。</span>
          </p>
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="127"
            height="45"
            src="~@/assets/images/solution/group/group6.png"
            alt=""
          />
        </div>
        <p style="margin-top: 0.35rem">
          区域心电中心由心电监护手机终端、医院监护中心服务器和网络通信支持三部分组成。通过导联兼容的数字式全信息记录发射器，
          可以连续采集患者各种生活状态下的心电信息，监测心脏电生理变化。利用移动技术，发送监测数据，自动分析诊断预警，接收医生下达的诊断医嘱；
          利用现代网络技术将长时间监测心电信息传输到监护中心，通过动态心电分析软件，检查分析患者多种症状，给出诊断报告。
        </p>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img style="width:6rem;height:6.38rem"
            src="~@/assets/images/solution/solution4.png"
            alt=""
          />
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="127"
            height="45"
            src="~@/assets/images/solution/group/group5.png"
            alt=""
          />
        </div>
        <div class="solution-decribe-fan" style="margin-bottom: 0.5rem">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>提供对冠心病的早期诊断； </span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>提供对心肌梗死患者的愈后评估;</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span> 提供对PTCA术后患者了解有无再狭窄;</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >提供对发作性心律失常进行定性和定量分析及进行危险性的评价。</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component
export default class SolutionInfoView extends Vue {}
</script>

<style lang="scss" scoped>
.Internet-top {
  //   width:19.20rem;
  height: 5.23rem;
  background: rgba(8, 31, 82, 1);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .Internet-top-innerBox {
    display: flex;
    flex-direction: column;
    width: 12rem;
    .top-innerBox-number {
      //   width: 2.82rem;
      height: 3.18rem;
      font-size: 2.6rem;
      font-family: DIN-Bold;
      font-weight: bold;
      color: rgba(4, 81, 132, 1);
      position: relative;
      text-align: center;
    }
    .top-innerBox-text {
      //   width: 7.27rem;
      height: 0.8rem;
      font-size: 0.55rem;
      line-height: 0.8rem;
      font-family: Poppins-Bold;
      background: rgba(8, 31, 82, 1);
      opacity: 1;
      position: absolute;
      top: 1.4rem;
      right: 3.5rem;
      text-align: center;
      color: #fff;
      font-weight: normal;
    }
    .top-innerBox-text1 {
      //   width: 3rem;
      font-size: 0.14rem;
      font-family: Poppins-Bold;
      line-height: 0.26rem;
      color: rgba(255, 255, 255, 1);
      opacity: 1;
      text-align: center;
    }
  }
}
/*    第一部分*/
.Internet-top1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  .Internet-top-text {
    margin-top: 0.7rem;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: center;
    width: 12rem;
    .Internet-top-text1 {
      font-size: 0.6rem;
      font-family: DINCond-BlackAlternate;
      font-weight: 400;
      line-height: 0.6rem;
      color: rgba(41, 180, 115, 1);
      opacity: 1;
    }
  }
  .Internet-top-inner-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.2rem;
    height: 0.6rem;
    border-bottom: 1px solid rgba(41, 180, 115, 1);
    position: relative;
    .inner-two-text1 {
      font-size: 0.16rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(41, 180, 115, 1);
      opacity: 1;
    }
    .inner-two-text2 {
      font-size: 14px;
      font-family: DIN Light;
      font-weight: 400;
      color: rgba(41, 180, 115, 1);
      opacity: 1;
    }
  }
  .Internet-top-inner-two:after {
      content: "";
      position: absolute;
      bottom: 0;
      left:0;
      width:0.4rem;
      height: 0.04rem;
      background:#29B473;
      display: inline-block;
  }
  .solution-decribe {
    margin-top: 0.3rem;
    .solution-decribe-box {
      display: flex;
      flex-direction: row;
      margin-top: 0.3rem;
      .solution-decribe-box-cion {
        width: 0.2rem;
        height: 0.2rem;
        background: rgba(41, 180, 115, 1);
        border-radius: 50%;
        opacity: 1;
        margin-right: 0.1rem;
        text-align: center;
        line-height: 0.2rem;
        color: #fff;
      }
      .solution-decribe-box-text {
        flex: 1;
      }
    }
  }
  .solution-decribe-fan {
    margin-top: 0.3rem;
    p {
      line-height: 0.4rem;
    }
  }
}
</style>
