
import { Vue, Component } from "vue-property-decorator";

import { jobDetailDto } from "dtos/jobDetail";
import data from "./data"

@Component
export default class RecruitView extends Vue {
  TabData: jobDetailDto[] = data;

  toDetailInfo(index: number) {
    this.$router.push({
      name: "recruitDetail",
      query: { i: index+'' }
    });
  }
}
