export default [
    {
        name: ".net后端开发工程师",
        salary: "面谈",
        jobYears: "3年",
        education: "本科",
        jobAdress: "成都",
        describe: [
            "使用C#进行后端开发，实现高效稳定的应用",
            "根据产品具体要求，承担开发任务，按质按量完成任务目标",
            "完成项目编码、单元测试、协助前端以及测试解决开发中的问题，并对开发模块质量负责"
        ],
        jobAsk: [
            "计算机相关专业本科以上学历，3年实际开发经验",
            "精通ASP.NET编程，掌握常用设计模式，有良好的编程习惯和良好的文档编写能力",
            "熟悉MVC、WebAPI、EF、JQuery、AJAX、Redis、RabbitMQ等常用技术，掌握Bootstrap、angular、vue优先",
            "熟悉一种或多种关系数据库，如Oracle或MySQL，精通sql语言，有存储过程编写经验",
            "有Android原生开发经验优先",
            "良好的抽象思维和逻辑思维，独立分析和解决问题的能力",
            "能够独立解决问题，能承受一定的压力",
            "有大数据信息系统或医疗行业开发经验优先"
        ]
    },
    {
        name: "web前端工程师",
        salary: "面谈",
        jobYears: "3年",
        education: "本科",
        jobAdress: "成都",
        describe: [
            "高质量实现产品要求，高精度还原视觉设计师的成果",
            "使用Vue技术完成前端开发工作；JavaScript程序模块开发，通用类库、框架编写",
            "负责PC浏览器端和移动H5端功能模块的设计及组件化开发工作",
            "协助前端开发团队完成前端框架的重构"
        ],
        jobAsk: [
            "计算机相关专业本科毕业，3年前端开发工作经验",
            "熟练掌握H5、CSS3，ES6/ES7，能够熟练基于Vue完成业务逻辑开发工作",
            "理解MVC和RESTFUL，良好的学习能力、沟通能力和高度的责任心",
            "有企业管理类产品研发经验优先",
            "能够独立解决问题，能承受一定的压力"
        ]
    },
    {
        name: "ui设计师",
        salary: "6K-8K",
        jobYears: "2年",
        education: "大专及以上学历",
        jobAdress: "成都",
        describe: [
            "负责公司软件项目展示内容的设计、编辑、美化等工作",
            "负责公司形象展示宣传、产品图册设计、宣传册设计等平面类设计工作",
            "根据产品需求，对产品整体的美术风格、界面结构、操作流程等做出设计",
            "负责与开发人员配合完成软件UI界面前台页面设计和编辑",
            "深入理解项目产品流程、用户操作流程及用户需求",
            "负责公司网站、微信公众号的设计、改版、更新、维护，对整体美观度的把控",
            "根据项目方案完成PPT的制作",
            "完成领导交给的其他相关工作"
        ],
        jobAsk: [
            "美术设计类、平面设计专业或计算机软件相关专业，统招大专及以上学历，2年及以上设计经验",
            "精通PS、AI、AE 等相关平面设计软件及office办公软件",
            "有较强的艺术鉴赏力和独特的创意，敏锐的时尚触觉，较强的字体设计及色彩感觉",
            "具有较强的UI整体风格策划能力和实际技能与经验，能够主动与软件工程师沟通协调，确保UI效果的无缝整合、实现",
            "良好的学习能力和沟通能力，工作积极主动，具有强烈的责任心和团队合作精神",
            "熟悉医疗、软件行业特点，能按项目需求进行设计工作，能按时、按质完成工作任务",
            "要求面试提供项目案例或设计作品"
        ]
    },
    {
        name: "测试工程师",
        salary: "面谈",
        jobYears: "2年",
        education: "本科",
        jobAdress: "成都",
        describe: [
            "负责公司软件产品的功能、性能测试",
            "搭建测试环境",
            "根据需求文档，编写和维护测试用例",
            "执行测试",
            "公司或部门安排的其他相关工作"
        ],
        jobAsk: [
            "2年以上测试工作经验",
            "大学本科学历，计算机、软件工程等相关专业毕业",
            "熟悉测试工作流程和测试方法",
            "熟悉功能测试用例的各种设计方法",
            "熟悉禅道等项目管理工具",
            "具有较强的分析问题、定位问题的能力",
            "热爱测试工作，喜欢思考，责任心强，具有良好的团队合作精神和积极主动的沟通意识"
        ],
        advantage: [
            "有Web类产品测试经验",
            "熟练掌握SQL语句",
            "使用过Loadrunner或Jmeter测试工具经验的优先",
            "有医疗行业工作经验经历优先"
        ]
    },
    {
        name: "测试经理",
        salary: "面谈",
        jobYears: "无",
        education: "本科",
        jobAdress: "成都",
        describe: ["自动化测试，性能测试，功能测试"],
        jobAsk: [
            "负责制定建立测试流程和规范来保障产品的质量",
            "负责管理和运用BUG相关平台工具(如JIRA等)",
            "负责测试技术的推广和提升，拓展测试覆盖面和深度",
            "负责带领组员完成产品版本测试，以保障产品的质量",
            "根据需要建立并维护相关测试文档和测试用例规范等",
            "参与执行测试的工作",
            "跟踪BUG状态，并推动相关BUG得到及时合理地解决"
        ]
    },
    {
        name: "产品经理",
        salary: "面谈",
        jobYears: "3年",
        education: "无",
        jobAdress: "成都",
        describe: [
            "负责根据公司产品规划进行产品设计，包含功能、流程、交互设计",
            "负责输出规范、完善的产品原型、交互设计成果",
            "关注产品开发进度，解决开发过程中业务问题",
            "关注产品使用情况，收集使用过程中问题并重新设计、解决",
            "更多的思考产品不足之处，对比同类产品优点，对产品进行优化设计"
        ],
        jobAsk: [
            "具有3年以上实际产品设计及管理经验",
            "具有很好的积极性和责任心",
            "熟悉医疗行业业务"
        ]
    },
    {
        name: "出纳",
        salary: "面谈",
        jobYears: "无",
        education: "大学专科以上学历",
        jobAdress: "成都",
        describe: [
            "负责日常收支的管理和核对",
            "负责收集和审核原始凭证，保证报销手续及原始单据的合法性、准确性",
            "管理公司各银行帐户，负责开户登记、销户注销以及银行证卡的管理。负责与银行的一般业务接洽",
            "负责银行结算业务，按时准确核对各类银行帐务，及时清算未达帐项",
            "按规定购买、保管和存放支票、现金、票据，及时盘点登记，保证帐实、帐证相符"
        ],
        jobAsk: [
            "大学专科以上学历，会计学或财务管理专业毕业，持会计上岗证，有软件企业背景优先",
            "熟悉操作财务软件、Excel、Word 等办公软件",
            "记账要求字迹清晰、准确、及时，账目日清月结，报表编制准确、及时",
            "具备良好的沟通能力，善于处理流程性事务，有良好的独立工作能力和财务分析能力",
            "具备良好的沟通表达能力和学习能力",
            "了解国家财经政策和会计、税务法规，熟悉银行结算业务"
        ]
    },
    {
        name: "行政文员",
        salary: "面谈",
        jobYears: "无",
        education: "大专以上学历",
        jobAdress: "成都",
        describe: [
            "对公司外部合作单位及同事提供及时有效的沟通和支持",
            "辅助公司人员招募及办理各项相关材料",
            "参与公司项目投标文件撰写、日常文件撰写",
            "办公室的行政事宜",
            "处理一般商务事宜",
            "公司项目相关事宜处理",
            "公司合同相关核审签订",
            "完成上级安排的其它工作"
        ],
        jobAsk: [
            "大专以上学历，有过相关行政人事经验",
            "沟通能力强，具有主人翁精神和团队合作精神，良好的管理能力",
            "熟悉办公室行政管理知识及工作流程 ，熟悉公文写作，具备基本商务信函写作能力， 熟练运用OFFICE，EXCEL，PS,PPT 等办公软件",
            "工作仔细认真、责任心强、为人正直，具备较强的书面和口头表达能力"
        ]
    },
    {
        name: "行政主管",
        salary: "面谈",
        jobYears: "无",
        education: "大专以上学历",
        jobAdress: "成都",
        describe: [
            "对公司外部合作单位及同事提供及时有效的沟通和支持",
            "辅助公司人员招募及办理各项相关材料",
            "参与公司项目投标文件撰写、日常文件撰写",
            "办公室的行政事宜",
            "处理一般商务事宜",
            "公司项目相关事宜处理",
            "公司合同相关核审签订",
            "公司相关制度编写执行",
            "办公室事务管理执行",
            "完成上级安排的其它工作"
        ],
        jobAsk: [
            "大专以上学历，有过相关行政人事经验",
            "沟通能力强，具有主人翁精神和团队合作精神，良好的管理能力",
            "熟悉办公室行政管理知识及工作流程 ，熟悉公文写作，具备基本商务信函写作能力， 熟练运用OFFICE，EXCEL，PS,PPT 等办公软件",
            "工作仔细认真、责任心强、为人正直，具备较强的书面和口头表达能力"
        ]
    },
    {
        name: "会计",
        salary: "面谈",
        jobYears: "无",
        education: "本科及本科以上",
        jobAdress: "成都",
        describe: [
            "负责项目相关的会计核算",
            "正确进行会计核算，准确、及时地做好账务和结算工作",
            "负责会计总帐、明细帐的记录",
            "负责企业收入、费用、成本的核算",
            "负责税务核算，对应上交的各项税金，保证正确无误",
            "协助公司内部或外部开展财务审计等相关工作，对向公司内外提供的会计信息要认真审查，保证质量",
            "负责建立并登记合同收、付款台帐，加强往来帐目的管理，能及时提供客户的往来款明细资料，提供应收款的帐龄分析",
            "及时做好会计凭证、帐册、报表等财会资料的收集、汇编、归档等会计档案管理工作",
            "完成领导交办的其他临时性业务"
        ],
        jobAsk: [
            "财务、会计专业，本科及本科以上，有软件行业经验优先",
            "了解和掌握财务管理知识，规范化知识，计算机知识，统计知识等",
            "熟悉会计操作、会计核算流程管理、财务报表及基本的分析，银行业务和报税流程，国家税收政策及相关账务的处理方法",
            "具备财务软件使用经验",
            "具备良好的团队协作能力、表达能力、学习能力、分析能力、熟练操作办公软件能力",
            "工作细致严谨、品行端正、爱岗敬业"
        ]
    },
    {
        name: "技术经理",
        salary: "面谈",
        jobYears: "5年",
        education: "无",
        jobAdress: "成都",
        describe: [
            "负责产品的整体技术框架搭建，核心底层开发",
            "负责开发人员团队带领，计划拆分、协调，任务管理",
            "负责开发规范性执行，开发流程跟踪"
        ],
        jobAsk: [
            "具有5年以上实际开发经验，2年以上开发管理经验",
            "具有任务拆分、跟踪、管理能力",
            "熟悉软件开发流程规范，并能较好的在开发团队推行",
            "具有较好的产品理解能力，较为全面的技术覆盖面",
            "熟悉医疗行业业务"
        ]
    },
    {
        name: "人事主管",
        salary: "面谈",
        jobYears: "5年",
        education: "本科以上学历",
        jobAdress: "成都",
        describe: [
            "负责公司人力资源工作的规划，建立、执行招聘、培训、考勤、劳动纪律等人事程序或规章制度",
            "负责制定和完善公司岗位编制，协调公司各部门有效的开发和利用人力，满足公司的经营管理需要",
            "做好各岗位的职位说明书，并根据公司职位调整组要进行相应的变更，保证职位说明书与实际相符",
            "负责办理入职手续，负责人事档案的管理、保管、用工合同的签订",
            "建立并及时更新员工档案，做好年度/月度人员异动统计（包括离职、入职、晋升、调动、降职等）",
            "制定公司及各个部门的培训计划和培训大纲",
            "负责拟定部门薪酬制度和方案，建立行之有效的激励和约束机制",
            "制定绩效评价政策，组织实施绩效管理，并对各部门绩效评价过程进行监督和控制，及时解决其中出现的问题，使绩效评价体系能够落到实处，并不断完善绩效管理体系",
            "负责审核并按职责报批员工定级、升职、加薪、奖励及纪律处分及内部调配、调入、调出、辞退等手续",
            "做好员工考勤统计工作，负责加班的审核和报批工作",
            "负责公司员工福利、社会保险、劳动年检的办理",
            "配合其他部门做好员工思想工作，受理并及时解决员工问题",
            "其它领导安排的工作"
        ],
        jobAsk: [
            "本科以上学历，人力资源管理、行政管理等相关专业，具备hr专业知识，有过5年以上相关行政人事经验，有软件行业经验优先",
            "沟通能力强，具有主人翁精神和团队合作精神，良好的管理能力",
            "熟悉办公室行政管理知识及工作流程 ，熟悉公文写作，具备基本商务信函写作能力， 熟练运用OFFICE，EXCEL，PS,PPT 等办公软件",
            "工作仔细认真、责任心强、为人正直，具备较强的书面和口头表达能力",
            "了解国家务项劳动人事法规政策",
            "有强烈的责任感和敬业精神，公平公正、做事严谨，能承受较大的工作压力"
        ]
    },
    {
        name: "人事专员",
        salary: "面谈",
        jobYears: "1年",
        education: "无",
        jobAdress: "成都",
        describe: [
            "能独立开展基础岗位的招聘工作，包括需求沟通，职位发布，邀约面试，初试，候选人跟进",
            "维护与开拓招聘渠道，确保招聘的时效性",
            "协助上级开展薪酬绩效，员工关系等工作",
            "负责拟定同类竞争公司的人才地图"
        ],
        jobAsk: [
            "一年以上互联网行业人力资源工作经验",
            "熟练掌握招聘相关技能",
            "上进好学，想要在人力资源方向有所发展者优先",
            "有绩效管理相关经验者优先"
        ]
    },
    {
        name: "软件实施工程师",
        salary: "面谈",
        jobYears: "2年",
        education: "本科及以上学历",
        jobAdress: "成都",
        describe: [
            "负责公司产品在项目上部署以及系统调试，根据项目目标把控实施节奏，保证系统调通正常运行",
            "沉淀在实施过程中出现的问题和状况，形成文档，迭代部署实施方案",
            "针对实施需求设计系统运维工具，为售后提供技术支持和工具保障",
            "及时了解客户需求、对客户的满意度进行管理；主动了解同行产品或方案，给公司提供产品和方案的改进意见"
        ],
        jobAsk: [
            "本科及以上学历，计算机、软件工程相关专业",
            "熟悉Windows操作系统及常用组件，掌握Oracle、MySQL、SQL Server等数据库之一，能够熟练编写T-SQL代码和存储过程者优先",
            "两年以上软件开发实施或硬件维护经验，有过全数字化医院技术、医院集成平台、区域化医疗、网络医疗经验者优先",
            "有过接口开发经验者优先，有使用过ESB总线基于SOA开发者优先",
            "具备客户沟通、协调能力，有良好的语言组织能力",
            "具有良好的职业素养，责任心强，具有较强的学习能力、沟通协调能力及统筹能力，具良好的服务意识及团队精神",
            "适应能力、抗压能力强，可以经常出差"
        ]
    },
    {
        name: "售前支持",
        salary: "面谈",
        jobYears: "3年",
        education: "本科及以上学历",
        jobAdress: "成都",
        describe: [
            "配合销售人员进行销售项目售前支持与策划，完成销售项目中的售前相关技术和项目工作",
            "准确有效的挖掘用户需求，完成业务咨询、客户需求分析，以及方案设计、项目规划等多种方案制作",
            "负责项目的招投标、市场活动、演示讲解及相关会谈等工作"
        ],
        jobAsk: [
            "理工科背景，软件及计算机等类专业本科以上学历",
            "熟悉售前流程、招投标过程，从事过软件开发、信息系统集成行业的售前支持、需求分析、项目实施、有厂商资源等优先考虑",
            "熟练掌握Office办公软件",
            "能与用户较好的沟通，较强的文字与口头表达能力及沟通协作能力，演讲和文档呈现、学习理解、分析归纳能力",
            "具有独立的客户交流、谈判、竞争对手分析能力，熟悉相关的商务技巧",
            "具有团队协作精神，有一定组织协调能力，具有较强的责任性，工作耐心、细致，能承受一定的工作压力",
            "具有3年售前工作经验，有医疗相关行业经验优先"
        ]
    },
    {
        name: "文案策划",
        salary: "面谈",
        jobYears: "2年",
        education: "本科及以上学历",
        jobAdress: "成都",
        describe: [
            "能够完成项目文案撰写",
            "主动跟进项目工程进度，收集并整理相关资料",
            "配合公司品牌部分文案撰写",
            "参与项目创意策略讨论，提出建议",
            "配合上级完成相关创意的文字表现工作",
            "主动收集其它广告公司的优秀广告作品",
            "配合上级进行区域竞品的广告表现分析",
            "参加项目标书制作",
            "公司内部的线上线下运营工作",
            "完成领导布置的其它工作内容"
        ],
        jobAsk: [
            "本科及以上学历， 2年以上相关工作经历 ，有制作标书经验优先，熟悉医疗软件行业优先",
            "良好的写作功底，过硬的文字驾驭能力 ， 具有一定的的市场分析能力",
            "具有发散型创意思维，善于独立思考，善于沟通，并具备良好的团队合作意识",
            "了解互联网及各类推广方式，能够熟练撰写发布各类稿件",
            "熟悉市场推广、品牌策划、活动策划的整个流程"
        ]
    },
    {
        name: "系统集成工程师",
        salary: "面谈",
        jobYears: "2年",
        education: "大专以上学历",
        jobAdress: "成都",
        describe: [
            "负责公司项目全生命周期中项目管理工作",
            "负责与公司客户进行积极有效的沟通协调，保证实施方案合理、过程顺利、用户满意",
            "负责项目实施过程中的问题及故障诊断，积极协调资源，有效解决项目现场存在的问题",
            "系统集成安装工作",
            "解决实施过程中遇到的常见问题，维护客户关系，保证客户满意度",
            "协助完成项目实施的方案、计划、执行、总结、培训、验收、服务及收款等相关工作"
        ],
        jobAsk: [
            "大专以上学历，计算机、电子或相关专业，有2年以上医疗软件项目实施管理经验者优先",
            "2年以上实施或硬件维护经验，熟悉Windows操作系统及常用组件，掌握服务器、存储、网络等基础系统",
            "熟悉IT系统集成所涉及的各种软硬件设备关键技术",
            "了解弱电工程相关施工工序、工艺、关键技术、系统功能的相关细节工作（包括现场布线、设备安装、调试、客户培训、工程文档编写等）",
            "要求工作态度严谨，责任心强，具备良好及团队协作能力，具有丰富的语言表达能力和沟通能力，较强的团队合作精神和领导协调管理能力"
        ]
    },
    {
        name: "项目实施运维经理",
        salary: "面谈",
        jobYears: "3年",
        education: "专科及以上学历",
        jobAdress: "成都",
        describe: [
            "完成信息化项目实施交付工作，包括调研、需求分析、业务规划和解决方案的制定、业务培训、开发过程管控、系统验收测试及上线，能够有效控制项目进度、范围、成本、质量、识别风险，按照项目计划保证项目成功交付",
            "负责项目上线后的运维管理工作，对相关需求进行处理或提出解决方案",
            "负责项目客户关系的维护，建立良好的沟通合作环境",
            "协调项目各团队完成项目的全过程管理工作",
            "合理进行项目资源协调及评估控制",
            "负责项目各类文档材料的编写工作"
        ],
        jobAsk: [
            "专科及以上学历，对医疗信息化、互联网医疗行业有独到的认知和见解",
            "具有敏锐的观察分析能力，擅长总结、分析，工作热心主动，注重沟通效率，注重团队协作，有强烈的责任感",
            "具备3年以上项目实施运维管理相关工作，熟悉医疗市场运作机制，有实施管理经验、医疗行业经验优先",
            "要求有以项目负责人身份实际参与并验收的项目不低于3个"
        ]
    },
    {
        name: "项目助理",
        salary: "面谈",
        jobYears: "3年",
        education: "本科以上学历",
        jobAdress: "成都",
        describe: [
            "以产品研发部现有人员、流程为基础，分析研发流程中可能存在的问题，制定产品研发质量管理标准流程，确保产品研发质量",
            "引导产品研发流程按照发布的体系流程进行研发活动，并进行过程审计、过程问题识别、质量风险预警",
            "组织制定软件项目质量保证计划，监控并推动项目各阶段质量目标的达成",
            "对产品迭代过程关键质量数据进行度量分析，针对问题和异常点，引导产品线团队开展质量回溯，推动问题的闭环、改进及预防",
            "执行软件项目质量跟踪，参与项目各个节点把关，识别风险，确保节点质量",
            "组织软件质量问题的专题改进，降低软件开发过程的质量风险",
            "研发质量日常管理工作"
        ],
        jobAsk: [
            "本科以上学历，软件工程、计算机相关专业",
            "3年以上软件质量管理工作经验，对CMMI、ISO有较为深入了解，有PMP、Scrum项目经验优先",
            "熟悉质量管理体系、敏捷开发，了解软件产品开发流程",
            "掌握产品研发流程及质量管理方法，通晓质量保证、项目管理等方面的知识",
            "熟练掌握度量及统计分析方法，能通过数据分析发现团队问题并促进改进",
            "熟练使用自动化办公软件、图表、总结、汇报类文件的撰写能力，具备良好的人际沟通和团队协作能力，有快速吸纳新知识的能力"
        ]
    },
    {
        name: "研发经理",
        salary: "面谈",
        jobYears: "5年",
        education: "无",
        jobAdress: "成都",
        describe: [
            "负责研发部门日常管理工作",
            "负责开发人员团队带领，计划拆分、协调，任务管理",
            "负责开发规范性执行，开发流程跟踪，保证按时完成开发工作"
        ],
        jobAsk: [
            "具有5年以上实际开发经验，2年以上开发管理经验",
            "具有任务拆分、跟踪、管理能力",
            "熟悉软件开发流程规范，并能较好的在开发团队推行",
            "具有较好的产品理解能力，较为全面的技术覆盖面",
            "熟悉医疗行业业务"
        ]
    },
    {
        name: "运维工程师",
        salary: "面谈",
        jobYears: "2年",
        education: "本科以上学历",
        jobAdress: "成都",
        describe: [
            "参与公司业务系统产品功能设计及实施工作",
            "负责客户调研、客户需求分析、项目部署、系统维护",
            "保障项目产品的正常运行",
            "项目产品的日常维护工作",
            "解决项目现场遇到的问题，维护客户关系，保证客户满意度",
            "领导安排的其它事宜"
        ],
        jobAsk: [
            "计算机相关专业本科以上学历，2年以上医疗软件项目实施管理经验者优先",
            "2年 以上实施维护经验，熟悉Windows操作系统及常用组件，掌握Oracle、MySQL、SQL Server等数据库之一，能够熟练编写T-SQL代码和存储过程者优先",
            "要求工作态度严谨，责任心强，具备良好及团队协作能力，具有丰富的语言表达能力和沟通能力，较强的团队合作精神和领导协调管理能力",
            "适应能力、抗压能力强，可以经常出差"
        ]
    },
    {
        name: "运营维护经理",
        salary: "面谈",
        jobYears: "3年",
        education: "本科以上学历",
        jobAdress: "成都",
        describe: [
            "完成信息化项目实施交付工作，包括调研、需求分析、业务规划和解决方案的制定、业务培训、开发过程管控、系统验收测试及上线，能够有效控制项目进度、范围、成本、质量、识别风险，按照项目计划保证项目成功交付",
            "负责项目上线后的运维管理工作，对相关需求进行处理或提出解决方案",
            "负责项目客户关系的维护，建立良好的沟通合作环境",
            "协调项目各团队完成项目的全过程管理工作",
            "合理进行项目资源协调及评估控制",
            "负责项目各类文档材料的编写工作"
        ],
        jobAsk: [
            "本科及以上学历，对医疗信息化、互联网医疗行业有独到的认知和见解",
            "具有敏锐的观察分析能力，擅长总结、分析，工作热心主动，注重沟通效率，注重团队协作，有强烈的责任感",
            "具备3年以上项目实施运维管理相关工作，熟悉医疗市场运作机制，有实施管理经验、医疗行业经验优先",
            "要求有以项目负责人身份实际参与并验收的项目不低于3个"
        ]
    },
    {
        name: "运营主管",
        salary: "面谈",
        jobYears: "3年",
        education: "本科以上学历",
        jobAdress: "成都",
        describe: [
            "负责公司整体运营体系，对一系列产品结合业务，落地开展日常运营工作",
            "有针对性地开展整合营销活动，协作策划活动方案并落地执行跟进",
            "根据公司现状，制定短中长期线上线下营销方案、活动策划执行跟进，扩大市场影响力",
            "收集市场信息，行业动态，用户反馈，分析总结竞争对手，为公司整体战略制定提供相关支持",
            "领导交待的其它任务等",
        ],
        jobAsk: [
            "本科以上学历，对医疗平台、互联网智能医疗行业有独到的认知和见解",
            "具有敏锐的观察分析能力，擅长总结、分析，工作热心主动，注重沟通效率，注重团队协作，有强烈的责任感",
            "具备3年以上运营相关工作，熟悉医疗市场运作机制，有医疗行业经验优先",
        ]
    },
    { name: "" }
];
