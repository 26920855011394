
    import {Vue, Component} from "vue-property-decorator";
    import {jobDetailDto} from "dtos/jobDetail";
    import data from "./data";
    import basicInfo from "untils/basicInfo";

    @Component
    export default class RecruitDtailView extends Vue {
        initData: jobDetailDto | null = null;
        basicInfo = basicInfo;

        mounted() {
            if (this.$route.query.i !== undefined) {
                let index = parseInt(this.$route.query.i as string);
                this.initData = data[index];
            }
            // console.log(this.initData)
        }

        back() {
            this.$router.push({path: '/joinUs/recruit'})
        }
    }
