<template>
  <div>
    <div class="Internet-top">
      <div class="Internet-top-innerBox">
        <div class="top-innerBox-number">
          <div style="position:relative;opacity: 0.45;">02</div>
          <div class="top-innerBox-text">区域医疗与数据共享</div>
        </div>
        <div class="top-innerBox-text1">
          <p>促进基层医疗信息化发展，实现卫生信息资源统一规划和集中管理，</p>
          <p>完全及充分利用数据资源，实现区域内卫生信息共享，</p>
          <p>为各级部门提供科学有效的决策支持。</p>
        </div>
      </div>
    </div>
    <div class="Internet-top1">
      <div class="Internet-top-text">
        <!-- 第一部分-->
        <div style="display: flex;flex-direction: column;align-items: center">
          <p class="Internet-top-text1">SMART MEDICINAL AND</p>
          <p
            class="Internet-top-text1"
            style="font-family:DIN;"
          >
            HEALTHCARE SOLUTION
          </p>
          <p style="font-weight: bold;font-size: 0.4rem;margin-top: 0.1rem">
            基层公卫与信息化解决方案
          </p>
          <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.7rem">
            基层公卫与信息化的整体解决方案，意义在于将基层的信息化系统和公共卫生系统深度结合使用
            。以乡镇社区为单位对所属的行政、业务、药械、财务、检验、检查、治疗、公共卫生等方面予以规范化系统化管理。
            符合国家健康档案相关标准，以人为本，在推进基础公共卫生三类九项的服务项目和实现社区卫生服务六位一体工作电脑化的基础上，
            将基层医疗的门诊住院业务管理、药品进销存管理、检验检查报告管理、财务统计查询等医疗业务主键结合，形成一个统一的整体。
            方案以居民预防保健、疾病控制为目的，以建立居民电子健康档案为重点，以医疗业务信息化为核心，
            围绕着居民健康档案的产生、发布、存储、使用，将家庭、社区、乡镇卫生院、卫生所、医院、预防保健机构、
            卫生管理部门等相关主体紧密的联系在一起，形成一个健康档案共享、功能前后无缝连接的完整平台。
          </p>
          <div style="margin-top: 0.4rem">
            <img
              width="1200"
              height="1095"
              src="~@/assets/images/solution/solution2/solution1.png"
              alt=""
            />
          </div>
        </div>

        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group3.png"
            alt=""
          />
        </div>
        <div class="solution-decribe-fan">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;">〉</span>
            <span
              >通过基础医疗业务和公共卫生业务的整合，使健康档案由“死档”变成“活档”；</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;">〉</span>
            <span
              >公共卫生管理融入到基本医疗业务办理流程中，实现了基层业务一体化办理，提高工作效率，优化业务流程；</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;">〉</span>
            <span
              >药品监管与供应系统建立了医疗机构与药品供应商之间的通道，实现药品配送一体化管理；</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;">〉</span>
            <span
              >为居民提供个性化、便捷、可及、连续的医疗服务和公共卫生服务，促进基本公共卫生服务的均等化；</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;">〉</span>
            <span
              >各类报表统计分析为卫生管理部门，强化卫生服务监管和绩效考核力度提供科学辅助决策依据。</span
            >
          </p>
        </div>

        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group7.png"
            alt=""
          />
        </div>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="973.61"
            height="855.11"
            src="~@/assets/images/solution/solution2/solution2.png"
            alt=""
          />
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">基本公共卫生服务信息系统</p>
          <p class="inner-two-text2">Public Health Service System</p>
        </div>
        <p style="margin-top: 0.35rem">
          基本公共卫生服务信息系统的目标完全符合《国家基本公共卫生服务规范》中的12大类，即城乡居民健康档案、健康教育、孕产妇健康管理、老年人健康管理、
          预防接种、传染病报告和处理、高血压患者健康管理、II型糖尿病患者健康管理、重性精神病患者管理。按照国家有关专项服务规范要求记录相应内容，
          通过信息化平台实现资源共享。提高卫生服务机构效能和管理水平。同时完成对所产生的信息进行管理，随时自动产生成各类型动态业务分析报告报表。
          卫生局等业务主管部门可以对辖区居民健康信息进行动态监控和管理，
          辖区居民可以通过网站查询个人健康档案等信息。实现卫生服务的统计、分析、报表、无纸化办公、共享居民健康数据等功能。
        </p>
        <p style="margin-top: 0.2rem">
          系统应用涉及城镇社区卫生服务中心、服务站、乡镇卫生院、村卫生室、疾病控制中心、预防保健机构、
          卫生行政管理等部门，为辖区居民起到医疗、预防、保健、康复、健康教育和计划生育技术指导的服务作用，
          同时为医疗机构了解和掌握辖区内居民的基本健康状况及其变化和趋势提供真实、动态的决策数据，为在辖区内开展人群健康分类管理，
          以行为危险因素干预为主要手段开展重点疾病的预防奠定基础，切实保障广大市民的身体健康，提高人民的生命质量。
        </p>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="971"
            height="687"
            src="~@/assets/images/solution/solution2/solution3.png"
            alt=""
          />
        </div>
        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">基层医疗机构信息化系统</p>
          <p class="inner-two-text2">Primary Hospital Information System</p>
        </div>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="969"
            height="767"
            src="~@/assets/images/solution/solution2/solution4.png"
            alt=""
          />
        </div>
        <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.7rem">
          基层医疗机构信息化系统按照国家基本医疗规范设计，满足城乡居民基本卫生服务需求，
          满足城乡居民健康档案管理、基本医疗服务、基层卫生管理、健康信息服务以及医疗卫生服务协同的目的，
          提高基层医疗卫生服务能力和工作质量，提升基层医疗卫生服务管理水平。并实现了卫生、医保、药监系统的有效衔接，
          提高了基层医疗卫生机构的工作效率和服务质量，降低了医疗费用，方便了群众就医。实现了信息共享、业务协同、
          实现了实时高效的基层医疗卫生服务监管，有效的提高了范围内基本医疗卫生服务的可及性和均等化水平。
        </p>

        <!--第二部分-->
        <div
          style="display: flex;flex-direction: column;align-items: center;margin-top: 1rem"
        >
          <p class="Internet-top-text1">BQ SOLUTION FOR PUBLIC HEALTH</p>
          <p
            class="Internet-top-text1"
            style="font-family:DIN;"
          >
            AND FAMILY DOCTOR
          </p>
          <p style="font-weight: bold;font-size: 0.4rem;margin-top: 0.1rem">
            全民健康与医生签约解决方案
          </p>
          <div style="margin-top: 0.4rem">
            <img
              width="971"
              height="707"
              src="~@/assets/images/solution/solution2/solution5.png"
              alt=""
            />
          </div>

          <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.7rem">
            “看病难、看病贵”一直是困扰医疗事业发展的瓶颈，更是全社会关注的焦点，医疗资源匹配不均匀的问题也颇为突出。为破解这些难题，
            建立全民人口健康信息平台，充分依托“互联网+”的优势资源，实现健康信息共享、远程医疗、慢病管理、分级诊疗等功能，患者及时就医便捷。
            扩展医生签约治疗模式，围绕家庭医生开展日常工作为主线，围绕家庭医生给居民进行签约管理、建立健康档案，
            进行健康评估、干预、追踪、随访等一系列公共卫生工作以及基本医疗服务。建立健康城市App管理软件，便于患者实时查看个人健康档案信息。
          </p>
          <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.5rem">
            在健康信息数据平台上，各级医疗机构依托互联网实现在线健康咨询、预约诊疗、候诊提醒、划价缴费、诊疗报告查询等便捷服务。有了这些功能，患者告别大排长龙的医院缴费窗口、
            水泄不通的就诊室通道和医疗专家一号难求的窘境，未来的就医问诊更便捷、更智能和低成本。
          </p>
        </div>
        <div style="margin-top: 0.6rem">
          <img
            src="~@/assets/images/solution/group/group1.png"
            alt=""
          />
        </div>
        <p style="margin-top: 0.2rem">
          一中心：暨配合医联体建设，依托上级医院，利用互联网技术建设的区域影像远程诊断、远程医疗中心。依此实现乡镇卫生院与区域内二、三级医院的紧密业务联系和技术支持。
        </p>
        <p style="margin-top: 0.2rem">
          两平台”：一是以网上就医咨询、预约挂号服务，检查检验结果查询及网上支付为主要内容的“互联网医院平台”。二是为实现居民电子健康档案在县域内的动态更新及检查、检验结果的互认等功能而建设规范统一的数据交换和共享平台
        </p>
        <p style="margin-top: 0.2rem">
          “多个应用系统”主要有实现系统内医学影像数据和检验数据的采集、诊断、传输、存储、交换和共享等功能的区域PACS、LIS系统；集视频监控、
          GPS定位及安全预警于一体的卫生应急指挥系统；依托数据交换和共享平台建设的卫生、计生业务监管和绩效评价系统；以促进合理诊疗和合理用药为目的建设的药品在线监管系统；
          为加强计划生育管理和服务而建设的计生管理系统；以及为方便群众而部署的实现个人档案的查询、就诊记录、体检、随访信息查询及检验检查结果查询等功能的健康城市APP。
        </p>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="969"
            height="741"
            src="~@/assets/images/solution/solution2/solution6.png"
            alt=""
          />
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group3.png"
            alt=""
          />
        </div>
        <div class="solution-decribe-fan">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;">〉</span>
            <span
              >打破信息孤岛状态，实现卫生计生一网覆盖、居民健康一卡通用、健康信息资源统一融合；</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;">〉</span>
            <span
              >建立人口全覆盖、生命全过程、中西医并重、工作全天候的全民医疗健康信息服务机制；</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;">〉</span>
            <span
              >运用健康医疗大数据分析，支撑业务集成、跨部门协同、社会服务和科学决策；</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;">〉</span>
            <span
              >发展个性化医疗服务，创新智能医疗业态和运行模式，实现预防、治疗、康复和健康管理一体化。</span
            >
          </p>
        </div>
        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">健康信息数据平台</p>
          <p class="inner-two-text2">Medical Data</p>
        </div>
        <p style="margin-top: 0.35rem">
          卫生部门通过健康信息数据平台进一步动态掌握卫生服务资源和利用信息，控制医疗费用的不合理增长、减少医疗差错。
          另一方面，医疗服务人员和公共卫生工作者掌握人口健康信息，
          做好疾病预防、控制和健康促进工作，广大居民掌控和获取自己的健康资料，参与健康管理，享受持续、跨地区、跨机构的医疗卫生服务。
        </p>
        <p style="margin-top: 0.35rem">
          同时依托健康信息数据平台，各级医疗机构实现信息化、智能化的升级换代，面向基层、偏远和欠发达地区，依托互联网开展远程病理诊断、
          影像诊断、专家会诊、监护指导、手术指导等远程医疗服务等举措也让这些地区享受到与发达地区同等的医疗待遇。
        </p>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="969"
            height="649"
            src="~@/assets/images/solution/solution2/solution7.png"
            alt=""
          />
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">家庭医生签约管理平台</p>
          <p class="inner-two-text2">Family Doctor Registration System</p>
        </div>
        <p style="margin-top: 0.35rem">
          家庭医生签约管理平台让基层的全科医生通过微信、手机APP等多种互联网线上工具向辖区内的群众提供签约服务。
          代替以往线下纸质签约方式带来的繁琐，有效提高了基层签约率及推进分级诊疗，是解决社会医疗成本过高的有效手段。
          利用互联网的便捷签约工具，能够快速实现签约，继而通过家庭医生签约服务平台与基层公卫系统对接向患者提供涵盖个人健康分析、慢病管理、康复保健个性化推送等服务，由传统的“被动式”服务向“主动式”服务演变，
          通过打造完成健康医疗闭环，提高医疗服务的可及性以及便利性，让普通百姓能够随时随地享受优质的医疗服务。
        </p>
        <div class="solution-cricle">
          <P class="solution-cricle-icon"></P>
          <P class="solution-text"
            >家庭医生签约管理平台，为各部门提供快捷高效的信息化服务。</P
          >
        </div>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="781"
            height="248"
            src="~@/assets/images/solution/solution2/solution8.png"
            alt=""
          />
        </div>
        <div class="solution-cricle">
          <P class="solution-cricle-icon"></P>
          <P class="solution-text"
            >家庭医生签约管理平台，为各部门提供快捷高效的信息化服务。</P
          >
        </div>
        <p style="margin-top: 0.35rem">
          家庭医生团队在管理部门的组织领导下形成，团队成员分别由基层医疗卫生机构全科医生、
          护士以及公卫医师，乡镇卫生院医生等构成家庭医生团队的基础，并与上级医院专科医师结成家庭医生团队。
        </p>
        <p style="margin-top: 0.35rem">
          家庭医生签约服务平台为家庭医生团队搭建了内部信息沟通平台，方便上下级医生间的信息沟通，病人数据传递，病情判断，
          诊治意见交流等；同时做到病人的诊治，给药，转诊等全过程自动记录保存。
          从而做到：一般病症的患者就可以在基层卫生机构有效看病就医；如果基层卫生医疗机构遇到不能解决的疾病问题，
          可以及时联系上级专业医生，得到及时的指导；若仍然不能解决问题，基层医生就可安排患者转诊到上级医院就诊；
          重疾康复病人可回到基层卫生医疗机构机构进行康复治疗，这就真正的实现了“健康进家庭、小病在基层、大病到医院、康复回基层”的新格局，
          这也真正地做到了国家所倡导的分级诊疗。家庭医生团队的组建，鼓励大医院专家到基层医疗机构服务，
          和基层家庭医生结成家庭医生团队。加大基层医疗人才的培养和引进，不断提高基层医疗机构医生的诊疗水平和服务力。
        </p>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="1201"
            height="559"
            src="~@/assets/images/solution/solution2/solution9.png"
            alt=""
          />
        </div>
        <div class="lastBox">
          <div class="lastBigBox">
            <div>
              <div style="height:176px;">
                <img
                  style="margin-top:30px;"
                  src="~@/assets/images/solution/solution2/solution11.png"
                  alt=""
                />
              </div>
              <h2 class="lastInfoTitle">管理端</h2>
            </div>
            <div class="detailInfo">
              <p>辖区内医疗机构与人员管理签约情况监测</p>
              <p>医疗服务及健康数据分析上报</p>
              <p>考核签约医生团队服务质量</p>
              <p>......</p>
            </div>
          </div>
          <div class="lastBigBox">
            <div>
              <div style="height:176px;">
                <img
                  style="margin-top:45px;"
                  src="~@/assets/images/solution/solution2/solution12.png"
                  alt=""
                />
              </div>
              <h2 class="lastInfoTitle">医生端</h2>
            </div>
            <div class="detailInfo">
              <p>管理签约居民健康档案</p>
              <p>管理签约居民电子病历</p>
              <p>远程诊疗与远程会诊</p>
              <p>在线学习与培训</p>
              <p>家庭医生团队间交流与管理</p>
            </div>
          </div>
          <div class="lastBigBox" style="border-color:#fff;">
            <div>
              <div style="height:176px;">
                <img
                  src="~@/assets/images/solution/solution2/solution13.png"
                  alt=""
                />
              </div>
              <h2 class="lastInfoTitle">居民端</h2>
            </div>
            <div class="detailInfo">
              <p>个人健康管理</p>
              <p>签约服务评价</p>
              <p>健康档案查询</p>
              <p>签约医生远程监护服务</p>
              <p>在线问诊</p>
              <p>接受健康信息与用药提醒</p>
            </div>
          </div>
          <!-- <img width="1217" height="553" src="~@/assets/images/solution/solution2/solution10.png" alt=""> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component
export default class SolutionInfoView extends Vue {}
</script>

<style lang="scss" scoped>
.Internet-top {
  // width:19.20rem;
  height: 5.23rem;
  background: rgba(8, 31, 82, 1);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .Internet-top-innerBox {
    display: flex;
    flex-direction: column;
    width: 12rem;
    .top-innerBox-number {
      //   width: 2.82rem;
      height: 3.18rem;
      font-size: 2.6rem;
      font-family: DIN-Bold;
      font-weight: bold;
      color: rgba(4, 81, 132, 1);
      position: relative;
      text-align: center;
    }
    .top-innerBox-text {
      //   width: 7.27rem;
      height: 0.8rem;
      font-size: 0.55rem;
      line-height: 0.8rem;
      font-family: Poppins-Bold;
      background: rgba(8, 31, 82, 1);
      opacity: 1;
      position: absolute;
      top: 1.4rem;
      right: 3.5rem;
      text-align: center;
      color: #fff;
      font-weight: normal;
    }
    .top-innerBox-text1 {
      //   width: 3rem;
      font-size: 0.14rem;
      font-family: Poppins-Bold;
      line-height: 0.26rem;
      color: rgba(255, 255, 255, 1);
      opacity: 1;
      text-align: center;
    }
  }
}
/*    第一部分*/
.Internet-top1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  .Internet-top-text {
    margin-top: 0.7rem;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: center;
    width: 12rem;
    margin-bottom: 0.3rem;
    .Internet-top-text1 {
      font-size: 0.6rem;
      font-family: DINCond-BlackAlternate;
      font-weight: 400;
      line-height: 0.6rem;
      color: rgba(41, 180, 115, 1);
      /*letter-spacing: 0.02rem;*/
    }
  }
  .Internet-top-inner-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.4rem;
    height: 0.6rem;
    border-bottom: 1px solid rgba(41, 180, 115, 1);
    position: relative;
    .inner-two-text1 {
      font-size: 0.16rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(41, 180, 115, 1);
      opacity: 1;
    }
    .inner-two-text2 {
      font-size: 14px;
      font-family: DIN Light;
      font-weight: 400;
      color: rgba(41, 180, 115, 1);
      opacity: 1;
    }
  }
  .Internet-top-inner-two:after {
      content: "";
      position: absolute;
      bottom: 0;
      left:0;
      width:0.4rem;
      height: 0.04rem;
      background:#29B473;
      display: inline-block;
  }
  .solution-decribe {
    margin-top: 0.3rem;
    .solution-decribe-box {
      display: flex;
      flex-direction: row;
      margin-top: 0.3rem;
      .solution-decribe-box-cion {
        width: 0.16rem;
        height: 0.16rem;
        background: rgba(41, 180, 115, 1);
        border-radius: 50%;
        opacity: 1;
        margin-right: 0.1rem;
        text-align: center;
        color: #fff;
      }
    }
  }
  .solution-decribe-fan {
    margin-top: 0.3rem;
    p {
      line-height: 0.4rem;
    }
  }
  .solution-cricle {
    display: flex;
    flex-direction: row;
    margin-top: 0.34rem;
    .solution-cricle-icon {
      width: 15px;
      height: 15px;
      border: 4px solid rgba(78, 157, 139, 1);
      border-radius: 50%;
      opacity: 1;
    }
    .solution-text {
      font-size: 0.16rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(38, 35, 33, 1);
      margin-left: 0.1rem;
    }
  }
}
.lastBox {
  display: flex;
  justify-content: space-around;
  margin-top: 0.35rem;
  .lastBigBox {
    flex: 1;
    border-right: 0.02rem solid #e4e4e3;
    text-align: center;
    .lastInfoTitle {
      color: #262321;
      font-size: 0.26rem;
      font-weight: bold;
      margin-top: 0.1rem;
    }
    .detailInfo {
      margin-top: 0.3rem;
      p {
        line-height: 0.36rem;
        color: #535453;
      }
    }
  }
}
</style>
