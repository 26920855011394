
import { Vue, Component } from "vue-property-decorator";
import ContactUs from "components/contactUs/index.vue";

@Component({
  name: "home",
  components: {
    ContactUs
  }
})
export default class Home extends Vue {
  toMore(val: number) {
    switch (val) {
      case 1:
        this.$router.push("/intoBQ");
        break;
      case 2:
        this.$router.push("/solution");
        break;
    }
  }
}
