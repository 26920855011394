<template>
  <div>
    <div class="Internet-top">
      <div class="Internet-top-innerBox">
        <div class="top-innerBox-number">
          <div style="position:relative;opacity: 0.45;">03</div>
          <div class="top-innerBox-text">院内信息化解决方案</div>
        </div>
        <div class="top-innerBox-text1">
          <p>
            数字化医院整体解决方案，统一接口设计规范，无缝衔接医院管理的每一个环节，实现物资管理可视化，
          </p>
          <p>医疗信息数字化，医疗过程规范化，</p>
          <p>医疗流程科学化，服务沟通人性化，医院管理智能化。</p>
        </div>
      </div>
    </div>
    <div class="Internet-top1">
      <div class="Internet-top-text">
        <!-- 第一部分-->
        <div style="display: flex;flex-direction: column;align-items: center">
          <p class="Internet-top-text1">HOSPITAL INFORMATION</p>
          <p class="Internet-top-text1" style="font-family:DIN;">
            INTEGRATION PLATFORM
          </p>
          <p style="font-weight: bold;font-size: 0.4rem;margin-top: 0.1rem">
            医院信息集成平台
          </p>
          <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.7rem">
            医院信息集成平台（Hospital information integration
            platform）就是把之前独立的系统如HIS、EMR、LIS、PACS、手麻、HRP等进行有效的整合。
            通过此平台来代替原来数量众多的点到点的数据接口，为医院信息化建设提供统一的数据标准和接口标准，实现医院不同业务系统与集成平台的有效集成和信息共享，
            实现临床信息一体化应用，提升医护工作者的工作效率和临床诊疗质量，建立临床文档库，推进电子病历的应用水平，建立医院统一运营数据中心，实现医院有效监管与科学决策，
            逐步推进智慧医院建设的战略目标。平台运用消息引擎等数据传输技术和标准化的消息封装手段实现院内应用系统的互联互通、通过异构系统、异构数据、异构网络、
            异构协议之间的信息集成和基于规范的数据交换实现共享，从而形成全院级的病人主索引，满足医疗信息集成和共享交换要求，在此基础上使医院信息系统平台形成一个标准化、
            集成化、智能化的信息平台，并建立对外连接医保、公共卫生、区域健康、社区医疗等多个信息系统，实现医院信息的规范化和一体化管理。
          </p>
        </div>

        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group8.png"
            alt
          />
        </div>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="970"
            height="324"
            src="~@/assets/images/solution/solution3/solution11.png"
            alt
          />
        </div>

        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group9.png"
            alt
          />
        </div>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="969"
            height="644"
            src="~@/assets/images/solution/solution3/solution12.png"
            alt
          />
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group10.png"
            alt
          />
        </div>

        <div class="solution-decribe-fan">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>四大目标：信息共享、互联互通、预警监管、辅助决策</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>三大中心：基础数据中心、临床数据中心、运营数据中心</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>三类集成：流程集成、数据集成、应用集成</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >五大工程：标准管理、业务总线、主题应用、统一门户、运维监管</span
            >
          </p>
        </div>

        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group11.png"
            alt
          />
        </div>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="975"
            height="1086"
            src="~@/assets/images/solution/solution3/solution13.png"
            alt
          />
        </div>

        <!--第二部分-->
        <div
          style="display: flex;flex-direction: column;align-items: center;margin-top: 1rem"
        >
          <p class="Internet-top-text1">HOSPITAL OPERATION</p>
          <p class="Internet-top-text1" style="font-family:DIN;">
            MANAGEMENT SOLUTION
          </p>
          <p style="font-weight: bold;font-size: 0.4rem;margin-top: 0.1rem">
            医院运营管理解决方案
          </p>

          <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.7rem">
            “医院运营管理解决方案的核心为医院资源规划计划（Hospital Resource
            Planning）是医院引入企业ERP的成功管理思想和技术
            ，融合现代管理理念和流程，整合医院已有的信息资源，创建一套支持医院整体运行管理的统一高效、互联互通、
            信息共享的系统化医院资源管理平台，使医院实现“人财物”、“医教研”、“护药技”管理科学化、规范化、精细化和可持续发展的战略转型。
          </p>
          <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.5rem">
            医院HRP系统的构建，能够帮助医院建立面向合理流程的管理模式，最大程度发挥医院资源效能，有效提升传统HIS的管理功能，
            从而使医院全面实现管理的可视化，使预算管理、成本管理、绩效管理科学化，使得医护分开核算、三级分科管理、零库存管理、顺价作价、多方融资、
            多方支付以及供应链管理等先进管理方法在医院管理中的应用成为可能。
          </p>
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group8.png"
            alt
          />
        </div>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="970"
            height="324"
            src="~@/assets/images/solution/solution3/solution14.png"
            alt
          />
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group12.png"
            alt
          />
        </div>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="970"
            height="655"
            src="~@/assets/images/solution/solution3/solution15.png"
            alt
          />
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group13.png"
            alt
          />
        </div>
        <div class="solution-decribe-fan">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >四大目标：业务一体化、管理全程化、决策智能化、系统平台化</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >两个平台：以医院信息集成平台为支撑平台，以医院运营管理平台为运用平台</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>一个导向：以精细化管理为导向</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >八大应用：人力资源、绩效管理、财务管理、成本核算、物流管理、资产管理、行政管理、决策管理</span
            >
          </p>
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">人力资源管理系统</p>
          <p class="inner-two-text2">Human Resources</p>
        </div>

        <p style="margin-top: 0.2rem">
          医院人力资源管理系统涵盖了医院人力资源管理相关的员工档案、岗位、排班、考勤、合同、薪酬福利、员工培训、医疗权限、科研管理、继续教育等内容，使人力资源管理者能够从繁琐的日常事务性工作中解脱，
          把更多精力用于人力资源的规划、招聘、培训开发与绩效管理等方面的工作。
        </p>
        <p style="margin-top: 0.2rem">
          系统通过精细化的操作权限控制实现了人力资源数据的集中统一管理和分布式应用，帮助医院构建起贯通人事、医务、护理、科教等多个职能科室的人力资源协同管理体系，
          建立实时更新、内容完整的人员档案信息数据库,进而在此基础上对员工档案数据进行深入利用，建立量化的考核评价体系，为员工的职称评定、内部晋升、绩效分配等工作提供数据支持。
          系统实现人力资源统一管理，管理对象包括正式员工、返聘人员、进修人员、研究生、实习生、工人等，由医院人力资源部、医务科、护理部、科研科、教学科、物业公司等多个部门根据权限进行分工维护。
          系统建立完整的人员信息基础数据满足其他信息系统调用需求，
          满足人员统一管理的要求，有效地解决了人员信息孤岛，从源头上规避因员工信息不统一、没有及时更新引发信息安全和医疗隐患等问题。
        </p>

        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group14.png"
            alt
          />
        </div>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="970"
            height="780"
            src="~@/assets/images/solution/solution3/solution16.png"
            alt
          />
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">财务管理系统</p>
          <p class="inner-two-text2">Financial Management</p>
        </div>

        <p style="margin-top: 0.35rem">
          医院财务管理系统帮助医院实现以会计核算为中心的业务流程整合，借助信息化手段将财务部门各项独立的工作充分整合起来，为医院构建一体化运行的财务管理平台奠定基础。重点完成整和周边各个关联业务系统（包括HIS门诊收费、住院收费、药品管理、固定资产、物资供应链管理等），实现医院各类凭证数据的自动生成和导入，减轻会计凭证手工处理工作量，方便医院实现物流、资产实物账与会计账的统一和收入数据的一致性。实现数据的集中处理，打通医院会计核算、成本核算、预算管理及合同管理之间的业务流程。财务人员在处理凭证的同时，可以实时进行预算的执行、合同付款的确认，并生成全成本核算所需的规范数据自动更新预算执行记录和合同的付款记录。不仅将减轻财务人员的日常工作量，也可以有效的提升医院财务的实时监督能力和整体内控水平。
        </p>

        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group15.png"
            alt
          />
        </div>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="970"
            height="537"
            src="~@/assets/images/solution/solution3/solution17.png"
            alt
          />
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">固定资产管理系统</p>
          <p class="inner-two-text2">Hospital Assets Management</p>
        </div>
        <p style="margin-top: 0.35rem">
          医院固定资产管理系统的管理对象涵盖医院的各类固定资产、医疗专业设备、通用设备、办公用品、交通工具、房屋及建筑物、低值易耗设备、
          信息化设备以及各类无形资产。除账内设备以外，系统还将实现对捐赠、试用、投放、科研等各类账外资产进行规范化管理。
          系统实现医院各类固定资产的统一管理，并对医疗设备从科室预算申报、招标、合同、安装验收、出入库、维修保养到报废处置进行全生命周期的数字化管理，
          全面满足医院规范化管理和质量控制的更多要求。
          帮助医院轻松完成财政部门固定资产数据的导出和上报，减少手工处理工作量。
        </p>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group15.png"
            alt
          />
        </div>

        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="970"
            height="635"
            src="~@/assets/images/solution/solution3/solution18.png"
            alt
          />
        </div>
        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">医院协同办公系统</p>
          <p class="inner-two-text2">Content Sharing and Collaboration</p>
        </div>
        <p style="margin-top: 0.35rem">
          医院协同办公系统针对目前医院中运行存在的沟通效率、公文流转、事务审批、知识共享等各类问题而开发的综合办公平台。
          系统为医院提供了一个高效运作的规范管理平台，方便实用的解决了医院的办公自动化需求，不仅大大提高了员工的日常办公效率，
          更重要的是各级管理层可以更好的实现医院的规范化、流程化管理，提升医院的整体执行力。系统可以实现医院人力资源、医务管理、护理管理、物资供应链、
          固定资产管理等其他软件的整合应用，
          真正满足医院的一体化办公和“人、财、物、质量”运营管理需求，从而实现对医院实时整体掌控。
        </p>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group15.png"
            alt
          />
        </div>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="971"
            height="748"
            src="~@/assets/images/solution/solution3/solution19.png"
            alt
          />
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">移动办公助理系统</p>
          <p class="inner-two-text2">Remote Office Assistant</p>
        </div>
        <p style="margin-top: 0.35rem">
          移动办公助理系统针对目前医院实际运营情况，生成医院各管理人员和操作人员的工作量日报，实时查询统计各类工作报表，
          通过APP和微信平台等各种方式推送查询。以讨论流的形式重构工作全流程，解决医院部门内沟通和医疗信息同步的协作要求，
          实现各部门无缝聊天、音视频会议、文件传阅批注分发、协同工作任务，以及随时随地对就诊患者的病情状况进行实时监控处置。
        </p>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="369"
            height="378"
            src="~@/assets/images/solution/solution3/solution20.png"
            alt
          />
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">医院物流管理系统</p>
          <p class="inner-two-text2">Hospital Logistic Management</p>
        </div>
        <p style="margin-top: 0.35rem">
          医院物流管理信息化系统通过对医院的科室需求管理、采购计划管理、订单管理、库存管理、条形码管理、消耗管理等功能，规范医院物流管理，
          并以最经济的资金占用率，保证物料的充分供应。
          减少医院库存资金的占用率，加快库存资金周转速度，降低医院的物资运营成本，提高医院物流管理的整体水平。
        </p>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="970"
            height="580"
            src="~@/assets/images/solution/solution3/solution21.png"
            alt
          />
        </div>

        <!--                第三部分-->
        <div
          style="display: flex;flex-direction: column;align-items: center;margin-top: 1rem"
        >
          <p class="Internet-top-text1">HOSPITAL APPOINTMENT</p>
          <p class="Internet-top-text1" style="font-family:DIN;">
            SERVICE SOLUTION
          </p>
          <p style="font-weight: bold;font-size: 0.4rem;margin-top: 0.1rem">
            医院预约服务解决方案
          </p>
          <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.7rem">
            医院预约服务解决方案集成了医院挂号预约服务平台与医技预约服务平台，旨在解决现行医疗体制长期以来造成的患者就诊观念僵化、
            医患关系紧张、医生水平差距悬殊及医疗资源分布不均等问题。解决现在医院挂号难、检查难等现实问题。
            借助信息化技术为社会公众推出一项医疗卫生便民服务，借用多种支付方式和平台，
            节省了公众看病排队时间，让广大患者朋友能公平获取医院的资源，缓解患者“看病难、排队难“的问题。
          </p>
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group16.png"
            alt
          />
        </div>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="970"
            height="674"
            src="~@/assets/images/solution/solution3/solution22.png"
            alt
          />
        </div>

        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group17.png"
            alt
          />
        </div>
        <div class="solution-decribe-fan">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>一个平台：挂号与医技预约一套系统，一个平台</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >两个目标：缓解患者“看病难、排队难“的问题，合理分配医疗资源</span
            >
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span
              >多种途径：APP、网站、114、银行、自助机、微信多种预约途径</span
            >
          </p>
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">医院预约挂号系统</p>
          <p class="inner-two-text2">Patient Registration</p>
        </div>
        <p style="margin-top: 0.35rem">
          医院预约挂号系统主要目的是为了缓解医院门诊挂号人满为患、等待时间长、异地不方便挂号等问题，减少秩序混乱、
          环境嘈杂等情况的发生，对医院就诊环境进行优化，并且患者通过网络来按照需求进行预约挂号，还可以减少不正常行为的产生，
          消除号贩子等违法乱纪行为。提高医院服务水平，缓解患者就诊压力过大的重要措施，可以使患者对医院的认同度提高，提高医院在社会上的形象
          ，实现社会效益与经济效益的双赢。还可以对患者就诊数量、
          病情种类以及医院出诊情况等进行统计分析，做好近期就诊工作的合理安排，提高综合服务水平。
        </p>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="970"
            height="666"
            src="~@/assets/images/solution/solution3/solution23.png"
            alt
          />
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">医技预约管理系统</p>
          <p class="inner-two-text2">Medicinal Resources Management</p>
        </div>
        <p style="margin-top: 0.35rem">
          医技预约管理系统可定制预约规则，规则实现智能预约管理方案，根据规则给临床科室、自助机终端、微信平台返回可预约的号源信息，
          依据号源信息为患者提供最优的预约检查安排。系统集成多种预约规则，包含预约队列、设备类型、检查部位、特殊检查、优先等级、医生排班、平均检查时间、
          平均等待时间，通过这些规则的制定，为患者智能合并预约申请单，返回号源信息。通过LED展示排队预约情况，患者排队语音叫号，
          减少患者的检查就诊体验。充分安排管理医院设备检查资源，提升医院的检查水平。
        </p>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="970"
            height="850"
            src="~@/assets/images/solution/solution3/solution24.png"
            alt
          />
        </div>
        <!--第四部分-->
        <div
          style="display: flex;flex-direction: column;align-items: center;margin-top: 1rem"
        >
          <p class="Internet-top-text1">SMART WARD</p>
          <p class="Internet-top-text1" style="font-family:DIN;">SOLUTION</p>
          <p style="font-weight: bold;font-size: 0.4rem;margin-top: 0.1rem">
            智慧病房解决方案
          </p>
          <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.7rem">
            医院预约服务解决方案集成了医院挂号预约服务平台与医技预约服务平台，旨在解决现行医疗体制长期以来造成的患者就诊观念僵化、
            医患关系紧张、医生水平差距悬殊及医疗资源分布不均等问题。解决现在医院挂号难、检查难等现实问题。
            借助信息化技术为社会公众推出一项医疗卫生便民服务，借用多种支付方式和平台，
            节省了公众看病排队时间，让广大患者朋友能公平获取医院的资源，缓解患者“看病难、排队难“的问题。
          </p>
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group8.png"
            alt
          />
        </div>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="557"
            height="551"
            src="~@/assets/images/solution/solution3/solution25.png"
            alt
          />
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group17.png"
            alt
          />
        </div>
        <div class="solution-decribe-fan">
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>病患：优质医护体验，解决看病难问题</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>医护人员：降低人力负担，减少人为出错</span>
          </p>
          <p>
            <span style="color: #43A346;font-weight: 600;margin-right:0.06rem;"
              >〉</span
            >
            <span>医院管理者：系统化管理病患信息，运用大数据优化经营策略</span>
          </p>
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group18.png"
            alt
          />
        </div>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            src="~@/assets/images/solution/solution3/solution26.png"
            alt
          />
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold"
            >1、床旁信息终端：</span
          >
          <span
            >以多媒体方式，清楚呈现病患诊疗计划与卫教信息，降低护理人员工作负担；一键求助与紧急联络功能，整合医护病三方通讯；提供多样化娱乐功能，如电视/游戏/上网等，强化服务满意度；专为病患与家属需求量身打造，创造优质住院环境。</span
          >
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold">2、数字床头卡：</span>
          <span
            >图文并茂，实时呈现病患住院数据；无纸电子化，提升行政效率；简易后台管理；现代化病房必须设备。</span
          >
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold"
            >3、互动多媒体信息系统：</span
          >
          <span
            >大屏幕搭配数字内容，可清楚传达医院信息与卫教倡导，降低护理人员工作负担；提供多样化信息娱乐功能，如电视/病患诊疗计划等，提升服务满意度；配合医院现有装潢，创造优质住院环境。</span
          >
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold">4、医疗互动APP：</span>
          <span
            >随时随地掌握相关住院信息，是最佳行动解决方案；可串联互动多媒体信息系统与护理站电子白板。</span
          >
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold"
            >5、室内空气质量监测：</span
          >
          <span
            >符合卫生部
            [室内空气质量]标准公告，以最有效率方式打造健康生活环境；连续日期不间断地自动侦测空气质量，数据上传云端永久储存；多样版位设计，提升医院形象；强化病房环境管理能力，让病患安心。</span
          >
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold"
            >6、患者生理数据监测：</span
          >
          <span
            >生命体征自动上传至医院数据库，缩短护理人员行政作业时间,减少数据抄录错误发生；改善医疗专业人员工作环境与提升临床照护质量；迈向无纸化智慧医院最佳方案；自动定位病患位置，提升住院安全。</span
          >
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold"
            >7、护士站电子白板：</span
          >
          <span
            >实时呈现各楼层病患状态；系统化管理护理人员任务调度，强化交班功能；提升工作效率，减少重工与沟通错误；可结合室内实时定位系统管理院内仪器设备动向。</span
          >
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold"
            >8、医院信息系统：</span
          >
          <span
            >是串联护理站与病房之有效沟通平台，集中显示移动护理车、护理站电子白板与数字床头卡信息；信息可永久储存追踪；可结合电子白板功能，整合度高。</span
          >
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold">9、移动护士站：</span>
          <span
            >移动EMR/PACS装置；随时随地与病床和护理站保持联系，提升住院效率。</span
          >
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold"
            >10、医用手持PDA：</span
          >
          <span>医护人员巡房助手。</span>
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold"
            >11、即时定位系统：</span
          >
          <span
            >实时显示所有人员、设备与资财定位讯息；降低管理成本，避免重复繁琐的定点调查与校正；可搭配手机进行紧急调度，提升医院效率。</span
          >
        </div>
        <div style="margin-top: 0.34rem">
          <span style="color: #29B473;font-weight: bold"
            >12、后台管理系统：</span
          >
          <span
            >提供完整系统管理架构；与HIS连接之平台；便利的行政管理工具。</span
          >
        </div>

        <!--                第五部分-->
        <div
          style="display: flex;flex-direction: column;align-items: center;margin-top: 1rem"
        >
          <p class="Internet-top-text1">UNIVERSAL HEALTH</p>
          <p class="Internet-top-text1" style="font-family:DIN;">SOLUTION</p>
          <p style="font-weight: bold;font-size: 0.4rem;margin-top: 0.1rem">
            全民体检解决方案
          </p>
          <p style="width: 12rem;font-size: 0.14rem;margin-top: 0.7rem">
            全民体检解决方案是实现了医院的体检中心无纸化，科技化的重要工具，贯穿整个体检流程从预约、登记、收费、分科检查到最后的总检
            ，每一项检查结果都会由每个科室通过软件直接汇总到总检科室，结束了每个项目结果都要人工录入的时代，也减小了由于人工录入所带来的误差
            ，提高了体检中心的工作效率。结合现在通用的门诊自助采集设备，自助采集病人体征信息，减少患者排队体检时间，缩减医生的体检时间，提高医院资源的利用率。
            方案将门诊自助采集设备系统与体检无缝对接，实现体征数据自助采集，自动传输，很大程度上减少了人力开销，并且提升了患者就诊体检的整体感受和对医院的认同感。
          </p>
        </div>
        <div style="margin-top: 0.6rem">
          <img
            width="173"
            height="45"
            src="~@/assets/images/solution/group/group16.png"
            alt
          />
        </div>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="969"
            height="779"
            src="~@/assets/images/solution/solution3/solution27.png"
            alt
          />
        </div>
        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">体检信息管理系统</p>
          <p class="inner-two-text2">
            Medicinal Examination Information Management
          </p>
        </div>
        <p style="margin-top: 0.35rem">
          体检信息管理系统对医院体检中心进行系统化和规范化的管理，大大提高体检中心的综合管理水平、工作效率。系统从业务数据的采集、输入、处理、加工和输出全由计算机来引领整个体检过程。
          为体检中心进一步实施客户健康管理服务和体检中心业务及行政管理的优化，提供了强有力的信息化支持。
        </p>
        <p style="margin-top: 0.35rem">
          体检信息管理系统围绕着健康档案的产生、发布、存储、使用，将体检中心、健康管理中心、体检者个人、
          体检者所在单位各个主题紧密联系在一起，完成健康体检和健康管理业务电脑信息化管理，形成了一个健康档案共享、
          功能前后链接的完整平台。系统为了能够适应不同体检中心的不同需求，许多功能都可以灵活设置，包括体检科室、
          体检项目、体检套餐、各种模板、体检常见结果、体检总检建议、常见疾病、报告格式等等。在基础数据的保障支持下，
          体检业务由开单->交费->检查->总检->报告打印->统计分析汇总整个历程全电子化、无纸化办公完成，此过程中智能诊断减少差错，
          达到自动诊断，自动生成总检结论；实现电子查体报告，免除报告分检工作，杜绝报告流失，缩短报告形成周期。系统把各独立科室串联起来，
          真正实现全数字无信息孤岛目标，最终建立基本的电子健康档案，实现健康信息资源共享，为医、教、研提供服务。破除信息孤岛，
          打造体检中心系统化和规范化管理，充分利用网络的优势，进行信息化管理给体检中心带来更好的社会效益和经济效益。
        </p>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="969"
            height="609"
            src="~@/assets/images/solution/solution3/solution28.png"
            alt
          />
        </div>

        <div class="Internet-top-inner-two">
          <p class="inner-two-text1">门诊自助采集系统</p>
          <p class="inner-two-text2">
            Clinic Registration and Medical Information
          </p>
        </div>
        <p style="margin-top: 0.35rem">
          为了减少患者的排队时间及就诊的效率，并且减少人工测量血压脉搏体温等数据，然后人工填写带来的繁琐步骤，
          和人工测量带来的失误率。所以采用门诊自助采集系统，采集系统包含身份识别模块，自测身高体重管理，自测血压脉搏和自测体温等方面，
          一键操作，不仅提升了医院的门诊工作效率，而且更加保证了测量数据的安全性和准确性，患者就诊满意度进一步提升。
        </p>
        <div
          style="margin-top: 0.4rem;display: flex;flex-direction: column;align-items: center"
        >
          <img
            width="957"
            height="463"
            src="~@/assets/images/solution/solution3/solution29.png"
            alt
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component
export default class SolutionInfoView extends Vue {}
</script>

<style lang="scss" scoped>
.Internet-top {
  // width: 19.20rem;
  height: 5.23rem;
  background: rgba(8, 31, 82, 1);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .Internet-top-innerBox {
    display: flex;
    flex-direction: column;
    width: 12rem;

    .top-innerBox-number {
      //   width: 2.82rem;
      height: 3.18rem;
      font-size: 2.6rem;
      font-family: DIN-Bold;
      font-weight: bold;
      color: rgba(4, 81, 132, 1);
      position: relative;
      text-align: center;
    }
    .top-innerBox-text {
      //   width: 7.27rem;
      height: 0.8rem;
      font-size: 0.55rem;
      line-height: 0.8rem;
      font-family: Poppins-Bold;
      background: rgba(8, 31, 82, 1);
      opacity: 1;
      position: absolute;
      top: 1.4rem;
      right: 3.5rem;
      text-align: center;
      color: #fff;
      font-weight: normal;
    }
    .top-innerBox-text1 {
      //   width: 3rem;
      font-size: 0.14rem;
      font-family: Poppins-Bold;
      line-height: 0.26rem;
      color: rgba(255, 255, 255, 1);
      opacity: 1;
      text-align: center;
    }
  }
}

/*    第一部分*/
.Internet-top1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  .Internet-top-text {
    margin-top: 0.7rem;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: center;
    width: 12rem;
    margin-bottom: 0.6rem;

    .Internet-top-text1 {
      font-size: 0.6rem;
      font-family: DINCond-BlackAlternate;
      font-weight: 400;
      line-height: 0.6rem;
      color: rgba(41, 180, 115, 1);
      /*letter-spacing: 0.02rem;*/
    }
  }

  .Internet-top-inner-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.4rem;
    height: 0.6rem;
    border-bottom: 1px solid rgba(41, 180, 115, 1);
    position: relative;
    .inner-two-text1 {
      font-size: 0.16rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(41, 180, 115, 1);
      opacity: 1;
    }

    .inner-two-text2 {
      font-size: 14px;
      font-family: DIN Light;
      font-weight: 400;
      color: rgba(41, 180, 115, 1);
      opacity: 1;
    }
  }
  .Internet-top-inner-two:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0.4rem;
    height: 0.04rem;
    background: #29b473;
    display: inline-block;
  }

  .solution-decribe {
    margin-top: 0.3rem;

    .solution-decribe-box {
      display: flex;
      flex-direction: row;
      margin-top: 0.3rem;

      .solution-decribe-box-cion {
        width: 0.16rem;
        height: 0.16rem;
        background: rgba(41, 180, 115, 1);
        border-radius: 50%;
        opacity: 1;
        margin-right: 0.1rem;
        text-align: center;
        color: #fff;
      }
    }
  }

  .solution-decribe-fan {
    margin-top: 0.3rem;

    p {
      line-height: 0.4rem;
    }
  }

  .solution-cricle {
    display: flex;
    flex-direction: row;
    margin-top: 0.34rem;

    .solution-cricle-icon {
      width: 15px;
      height: 15px;
      border: 4px solid rgba(78, 157, 139, 1);
      border-radius: 50%;
      opacity: 1;
    }

    .solution-text {
      font-size: 0.16rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(38, 35, 33, 1);
      margin-left: 0.1rem;
    }
  }
}
</style>
