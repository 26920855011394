
import { Vue, Component } from "vue-property-decorator";

@Component
export default class IntoBQView extends Vue {
  imgArr = [
    require("assets/images/IntoBQ/1.png"),
    require("assets/images/IntoBQ/2.png"),
    require("assets/images/IntoBQ/3.png"),
    require("assets/images/IntoBQ/4.png"),
    require("assets/images/IntoBQ/5.png"),
    require("assets/images/IntoBQ/6.png"),
    require("assets/images/IntoBQ/7.png"),
    require("assets/images/IntoBQ/8.png"),
    require("assets/images/IntoBQ/9.png")
  ];

  gswhArr = [
    {
      cnTitle:'企业愿景',
      enTitle:'Enterprise vision',
      info:['以医疗健康需求为中心，','成为创新引领的专业医疗科技公司'],
    },
    {
      cnTitle:'企业使命',
      enTitle:'Enterprise Mission',
      info:['运用前沿的科学技术让医疗机构使用数据对抗疾病，','改善就医途径，','实现更精准的医疗服务'],
    },
    {
      cnTitle:'服务理念',
      enTitle:'Service idea',
      info:['敏锐把握应用趋势，快捷满足客户需求，','以客户为中心，超越客户满意','以真诚之心，行信义之事'],
    },
  ];
  curIndex:number = 0;

  getCurIndex(val:number) {
    this.curIndex = val;
  }
}
