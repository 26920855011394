var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nodeFull"},[_c('div',{staticClass:"nodeFull"},[_vm._m(0),_c('div',{staticClass:"home-center"},[_c('div',{staticClass:"home-center-aboutus"},[_c('p',{staticClass:"aboutus-text"},[_vm._v("ABOUT US")]),_c('p',{staticClass:"aboutus-text1"},[_vm._v("关 于 我 们")]),_c('p',{staticClass:"aboutus-underline"}),_vm._m(1),_c('div',{staticClass:"aboutus-konwMore",on:{"click":function($event){return _vm.toMore(1)}}},[_vm._v("了解更多")])])]),_c('div',{staticClass:"home-center-secoend"},[_c('div',{staticClass:"home-center-secoend-box"},[_vm._m(2),_c('div',{staticClass:"home-center-secoendSerialnumber"},[_c('div',[_c('p',{staticClass:"secoendSerialnumbetext"},[_vm._v("01")]),_c('P',{staticClass:"secoendSerialLine"}),_c('P',{staticClass:"secoendSeriaytext"},[_vm._v("敏锐把握客户需求")])],1),_c('div',[_c('p',{staticClass:"secoendSerialnumbetext"},[_vm._v("02")]),_c('P',{staticClass:"secoendSerialLine"}),_c('P',{staticClass:"secoendSeriaytext"},[_vm._v("前沿技术贴合市场")])],1),_c('div',[_c('p',{staticClass:"secoendSerialnumbetext"},[_vm._v("03")]),_c('P',{staticClass:"secoendSerialLine"}),_c('P',{staticClass:"secoendSeriaytext"},[_vm._v("尖端人才引领创新")])],1),_c('div',[_c('p',{staticClass:"secoendSerialnumbetext"},[_vm._v("04")]),_c('P',{staticClass:"secoendSerialLine"}),_c('P',{staticClass:"secoendSeriaytext"},[_vm._v("突破超越客户满意")])],1)])])]),_vm._m(3),_c('div',{staticClass:"home-center-third"},[_vm._m(4),_c('div',{staticClass:"thirdTextBox"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"aboutus-konwMore",on:{"click":function($event){return _vm.toMore(2)}}},[_vm._v("了解更多")])])]),_c('div',{staticClass:"home-center-five"},[_vm._m(7),_c('ContactUs')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home-top"},[_c('div',{staticClass:"home-top-pic"},[_c('img',{attrs:{"src":require("@/assets/images/HomeImage/HomeImage.png"),"alt":""}})]),_c('div',{staticClass:"home-top-right"},[_c('p',{staticClass:"home-top-right-text"},[_vm._v("专业")]),_c('div',{staticClass:"home-top-right-text1",staticStyle:{"font-family":"none"}},[_c('p',[_vm._v("以医疗健康需求为中心")]),_c('p',[_vm._v("成为创新引领的专业医疗科技公司")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"aboutus-decreb"},[_c('p',[_vm._v("成都本千医疗科技有限公司是国内医疗健康领域领先的服务与产品供应商之一，")]),_c('p',[_vm._v("我们为各级各类医疗机构提供优质、专业的医疗服务解决方案与产品。")]),_c('p',[_vm._v("本千自成立以来，团队以专业、严谨、创新、诚信、激情的职业精神，")]),_c('p',[_vm._v("在中国医疗健康行业用数据对抗疾病，竭力为实际医疗健康需求和问题提供更高效、创新的最佳解决方案。")]),_c('p',[_vm._v("今天，本千已为近50家各级医疗机构提供医疗健康解决方案，平均每年提供居民医疗健康服务超过20万人次。")]),_c('p',{staticStyle:{"margin-top":"0.5rem"}},[_vm._v("本千关注医疗健康、重视全民健康，用心感知医疗需求，以专业传递价值。")]),_c('p',[_vm._v("欢迎您联系我们，了解有关本千的更多信息。")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home-center-secoendserve"},[_c('p',[_vm._v("给你最专业的服务")]),_c('p',{staticStyle:{"font-size":"0.4rem"}},[_vm._v("OUR SERVICE")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home-center-Fourth"},[_c('div',{staticClass:"home-center-aboutus"},[_c('p',{staticClass:"aboutus-text"},[_vm._v("OUR RESULTS")]),_c('p',{staticClass:"aboutus-text1"},[_vm._v("我们的成果")]),_c('p',{staticClass:"aboutus-underline"})]),_c('div',{staticClass:"Fourth-box"},[_c('div',{staticClass:"Fourth-box-1"},[_c('div',{staticStyle:{"margin-top":"0.1rem"}},[_c('img',{staticStyle:{"width":"0.66rem","height":"0.47rem"},attrs:{"src":require("@/assets/images/HomeImage/Icon.png"),"alt":""}})]),_c('div',[_c('p',{staticClass:"Fourth-box-1-text1"},[_vm._v("BQ Technology")]),_c('p',{staticClass:"Fourth-box-1-text2"},[_vm._v("将互联网技术与医疗服务深度融合的医疗企业")])])]),_c('div',{staticClass:"Fourth-box-2"},[_c('p',[_vm._v("• 为近50家各级医疗机构提供医疗健康解决方案")]),_c('p',[_vm._v("• 平均每年为超过20万居民提供医疗健康服务")]),_c('p',[_vm._v("•与四川大学华西医院、广东省第二人民医院等龙头医疗机构建立合作伙伴关系，共同实现网络协诊等医疗健康解决方案")]),_c('div',{staticClass:"Fourth-box-image"},[_c('img',{staticStyle:{"width":"5.46rem","height":"5.46rem"},attrs:{"src":require("@/assets/images/HomeImage/home-nv.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home-center-secoendserve"},[_c('p',[_vm._v("PRODUCTS AND")]),_c('p',{staticStyle:{"font-size":"1.6rem"}},[_vm._v("SOLUTIONS")]),_c('div',{staticClass:"thirdtext"},[_vm._v("解决产品和方案")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('p',{staticClass:"thirdTextBox-text"},[_vm._v("高效 · 安全 · 未来")]),_c('p',{staticClass:"thirdTextBox-text1"},[_vm._v("Efficiency / Security / Future")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"thirdTextBox-texts"},[_c('p',[_vm._v("本千为不同规模，不同行政级别的医疗机构提供统一或个性化的服务；致力于将医疗服务数字化，全面帮助医疗机构实现互联互通，为患者提供更优质的治疗方案。本千根据医疗健康服务体系的实际需求，通过大数据、云计算与最先进的物联网技术实现智慧医疗，为医疗健康行业提供全面覆盖、安全可靠的解决方案及产品。")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home-center-aboutus"},[_c('p',{staticClass:"aboutus-text"},[_vm._v("CONTACT US")]),_c('p',{staticClass:"aboutus-text1"},[_vm._v("联系我们")]),_c('p',{staticClass:"aboutus-underline"})])
}]

export { render, staticRenderFns }